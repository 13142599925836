import React, { useEffect } from 'react';
import RatingStars from './RatingStars';
import SecondaryBtn from './SecondaryBtn';
import { useNavigate } from 'react-router-dom';
import { useFireStore } from '../hooks/useFireStore';
import { useMyContext } from '../context/UseContext';
import { toast } from 'react-toastify';
import { useDocument } from '../hooks/useDocument';
import {
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { useState } from 'react';

const ProductCard = ({ liked, noLiked, product, index }) => {
  const navigate = useNavigate();
  const { user } = useMyContext();
  const [successMessage, setSuccessMessage] = useState('');

  const addToFavorite = async () => {
    if (user) {
      const docRef = doc(db, 'favorites', product.id);
      const ref = doc(db, 'products', product.id);

      setSuccessMessage('');
      try {
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          await deleteDoc(docRef);
          const favori = product.favori.filter((favori) => favori !== user.uid);
          await updateDoc(ref, {
            ...product,
            favori: favori,
          });
          setSuccessMessage('Favori supprimé avec succès !');
        } else {
          await setDoc(docRef, {
            ...product,
            uid: user.uid,
          });
          const favori = [];
          favori.push(user.uid);
          await updateDoc(ref, {
            ...product,
            favori: favori,
          });
          setSuccessMessage('Favori ajouté avec succès !');
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      navigate('/connexion');
    }
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage, {
        position: 'top-center',
      });
    }
  }, [successMessage]);

  const isFavori = product?.favori?.includes(user?.uid);

  return (
    <div className={`popular-item `}>
      <img
        src={product.galleries[0]}
        alt={product.name}
        className='popular-item_img'
      />
      <div className='popular-item__description'>
        <span>{product.price}€</span>
        <h4>
          {product.name.length > 25
            ? `${
                product.name.charAt(0).toUpperCase() +
                product.name.substring(1, 25)
              }...`
            : product.name.charAt(0).toUpperCase() + product.name.slice(1)}
        </h4>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <RatingStars rating={product.rating} />
          <button
            onClick={addToFavorite}
            style={{
              border: 'none',
              background: 'transparent',
              cursor: 'pointer',
            }}
          >
            {isFavori ? liked() : noLiked()}
          </button>
        </div>
        <SecondaryBtn onClick={() => navigate(`/produit/${product.id}`)}>
          Acheter
        </SecondaryBtn>
      </div>
    </div>
  );
};

export default ProductCard;
