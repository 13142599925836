import { useEffect, useRef, useState } from 'react';
import { db } from '../firebase/config';
import {
  collection,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';

export const useCollection = (
  collectionDoc,
  _filter,
  _category,
  _search,
  _minPrice,
  _maxPrice,
  _vedette,
  _novedette
) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    let ref = collection(db, collectionDoc);

    if (_search && _search.trim() !== '') {
      ref = query(
        ref,
        where('name', '>=', _search.trim()),
        where('name', '<', _search.trim() + '\uf8ff')
      );
    }

    if (_category && _category.length > 0) {
      ref = query(ref, where('category', 'in', _category));
    }

    if (_filter) {
      ref = query(ref, orderBy('name', _filter));
    }

    // getDoc(ref).then((snapshop) => {
    //   let results = [];

    //   snapshop.docs.forEach((doc) => {
    //     results.push({ id: doc.id, ...doc.data() });
    //   });

    //   setDocuments(results);
    // });

    if (_vedette) {
      ref = query(ref, where('sellingTypes', '==', 'en Vedette'));
    }
    if (_novedette) {
      ref = query(ref, where('sellingTypes', '!=', 'en Vedette'));
    }
    const unsub = onSnapshot(
      ref,
      (snapshop) => {
        let results = [];

        snapshop.docs.forEach((doc) => {
          results.push({ id: doc.id, ...doc.data() });
        });

        if (_minPrice) {
          results = results.filter((item) => Number(item.price) >= _minPrice);
        }
        if (_maxPrice) {
          results = results.filter((item) => Number(item.price) <= _maxPrice);
        }

        setLoading(false);
        setDocuments(results);
      },
      (err) => {
        setLoading(false);
        setError('Impossible de recurer les données');
      }
    );

    return () => unsub();
  }, [
    collectionDoc,
    _filter,
    _search,
    _category,
    _minPrice,
    _maxPrice,
    _vedette,
    _novedette,
  ]);

  return { documents, loading, error };
};
