import React from 'react';
import HeaderSlider from '../components/home/HeaderSlider';
import ProductCarousel from '../components/home/ProductCarousel';
import './home.css';
import PopularCaroul from '../components/home/PopularCaroul';
import { useCollection } from '../hooks/useCollection';
import Loader from '../utilities/Loader';

const Home = () => {
  const vedette = true;
  const novedette = true;
  const { documents, loading, error } = useCollection(
    'products',
    null,
    null,
    null,
    null,
    null,
    null,
    novedette
  );
  const windowWidth = window.innerWidth;

  const {
    documents: document,
    loading: isLoading,
    error: isError,
  } = useCollection('products', null, null, null, null, null, vedette);

  return (
    <main>
      <HeaderSlider />
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <span className='error'>{isError}</span>
      ) : (
        <ProductCarousel windowWidth={windowWidth} document={document} />
      )}

      <div>
        {loading ? (
          <Loader />
        ) : error ? (
          <span className='error'>{error}</span>
        ) : (
          <PopularCaroul
            documents={documents}
            loading={loading}
            error={error}
            title={'Produits populaire'}
          />
        )}
      </div>
    </main>
  );
};

export default Home;
