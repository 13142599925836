import { useEffect, useReducer, useState } from 'react';
import { useMyContext } from '../context/UseContext';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from 'firebase/auth';
import { auth, db } from '../firebase/config';
import { doc, setDoc } from 'firebase/firestore';

const initialSate = {
  user: null,
  isloading: false,
  error: null,
  success: null,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'IS_LOADING':
      return { ...state, isloading: true };
    case 'COMPLETE':
      return {
        isloading: false,
        error: null,
        user: action.payload,
        success: true,
      };

    case 'ERROR':
      return {
        ...state,
        user: null,
        isloading: false,
        error: action.payload,
        success: false,
      };

    case 'RESET':
      return { user: null, isloading: false, error: null, success: null };
    default:
      return state;
  }
};

export const useAuth = () => {
  const [authState, authDispach] = useReducer(authReducer, initialSate);
  const [isCancelled, setIsCancelled] = useState(false);
  const { dispatch } = useMyContext();

  const dispatchIfNotCancelled = (action) => {
    if (!isCancelled) {
      authDispach(action);
    }
  };

  const signin = (email, password) => {
    authDispach({ type: 'IS_LOADING' });
    signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        dispatch({ type: 'LOGIN', payload: res.user });
        dispatchIfNotCancelled({ type: 'COMPLETE', payload: res.user });
      })
      .catch((err) => {
        console.log(err.message);
        dispatchIfNotCancelled({
          type: 'ERROR',
          payload: `Mot de passe ou email invalide`,
        });
      });
  };

  const signup = async (email, password, displayName) => {
    authDispach({ type: 'IS_LOADING' });
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(res.user, { displayName });

      const userDocRef = doc(db, 'users', res.user.uid);
      await setDoc(userDocRef, {
        name: displayName,
        dateDeNaissance: '',
        phone: '',
        email,
        photo: '',
        isAdmin: false,
      });

      dispatchIfNotCancelled({ type: 'COMPLETE', payload: res.user });
      dispatch({ type: 'LOGIN', payload: res.user });
    } catch (err) {
      console.error(err.message);
      dispatchIfNotCancelled({
        type: 'ERROR',
        payload: `Mot de passe ou email invalide`,
      });
    }
  };

  const logout = () => {
    authDispach({ type: 'IS_LOADING' });
    signOut(auth)
      .then(() => {
        dispatchIfNotCancelled({ type: 'COMPLETE', payload: null });
        dispatch({ type: 'LOGOUT' });
      })
      .catch((err) => {
        console.log(err);
        dispatchIfNotCancelled({
          type: 'ERROR',
          payload: `Erreur de déconnexion`,
        });
      });
  };

  useEffect(() => {
    setIsCancelled(false);
    return () => setIsCancelled(true);
  }, []);

  return {
    authState,
    signin,
    signup,
    logout,
    dispatchIfNotCancelled,
  };
};
