import React, { useEffect, useState } from 'react';
import Layout from '../../components/admin/Layout';
import './notification.css';
import { useCollection } from '../../hooks/useCollection';
import Pagination from '../../utilities/Pagination';
import Loader from '../../utilities/Loader';
import { useFireStore } from '../../hooks/useFireStore';

const Notification = () => {
  const { loading, documents, error } = useCollection('messages');
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(6);
  const { updateDocument, isPending, success } = useFireStore('messages');

  const indexOfLastProduct = currentPage * productsPerPage;

  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const notifications = documents.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  useEffect(() => {
    const readUnreadMessages = async () => {
      try {
        const unReadMessages = documents.filter(
          (item) => item.isRead === false
        );

        await Promise.all(
          unReadMessages.map(async (item) => {
            await updateDocument(item.id, { ...item, isRead: true });
          })
        );
      } catch (error) {
        console.error(
          'Erreur lors de la mise à jour des messages non lus :',
          error
        );
      }
    };

    readUnreadMessages();
  }, [documents]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <Layout>
      <div>
        {loading ? (
          <Loader />
        ) : error ? (
          <div className='error'>
            <p>{error}</p>
          </div>
        ) : notifications.length === 0 ? (
          <div
            style={{
              width: '100%',
              backgroundColor: '#f6c6c6f5',
              color: ' #e22b2b',
              display: 'inline-block',
              padding: '10px',
            }}
          >
            <p>Aucun message disponible.</p>
          </div>
        ) : (
          <>
            <ul className='notifications-container'>
              {notifications.map((notification, index) => (
                <li key={index} className='notification'>
                  <h5>{notification.title}</h5>
                  <p>{notification.message}</p>
                  <ul>
                    <li>
                      <span>Email:</span>
                      <span>{notification.email}</span>
                    </li>
                    {notification?.phoneNumber && (
                      <li>
                        <span>phone:</span>
                        <span>{notification.phoneNumber}</span>
                      </li>
                    )}
                    {notification?.campany && (
                      <li>
                        <span>Entreprise:</span>
                        <span>{notification.campany}</span>
                      </li>
                    )}
                  </ul>
                </li>
              ))}
            </ul>
            <Pagination
              documents={documents}
              productsPerPage={productsPerPage}
              currentPage={currentPage}
              paginate={paginate}
            />
          </>
        )}
      </div>
    </Layout>
  );
};

export default Notification;
