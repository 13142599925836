import React from 'react';
import { Link } from 'react-router-dom';
import './emptyCart.css';

const EmptyCart = () => {
  return (
    <div className='empty-cart-container'>
      <div className='empty-cart'>
        <h2>Votre panier est vide</h2>
        <p>Commencez vos achats pour ajouter des produits à votre panier.</p>
        <Link to={'/boutique'}>
          <a className='btn-back-to-shop'>Retour à la boutique</a>
        </Link>
      </div>
    </div>
  );
};

export default EmptyCart;
