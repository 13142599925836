import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './shop.css';
import HeaderCategories from '../components/shop/HeaderCategories';
import HeaderFilter from '../components/shop/HeaderFilter';
import ContentRight from '../components/shop/ContentRight';
import ContentLeft from '../components/shop/ContentLeft';
import MobileHeader from '../components/shop/MobileHeader';
import { useCollection } from '../hooks/useCollection';
import { MyCategories } from '../data/data';

function Shop() {
  const [categories, setCategories] = useState([]);
  const [keywords, setKeywords] = useState(null);
  const [filter, setFilter] = useState('desc');
  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();

  const { documents, loading, error } = useCollection(
    'products',
    filter,
    categories,
    keywords,
    minPrice,
    maxPrice
  );
  const categorieHandler = (e) => {
    const category = e.target.value;
    setCategories([category]);
  };

  const filterHandler = (e) => {
    const filte = e.target.value === 'Croissant' ? 'asc' : 'desc';
    setFilter(filte);
  };
  return (
    <main className=' shop-main'>
      <div className='shop-main__header'>
        <HeaderCategories documents={documents} />
        <HeaderFilter setKeywords={setKeywords} setFilter={setFilter} />
      </div>
      <div className='shop-main__header-mobile'>
        <MobileHeader
          value={categories}
          onChange={categorieHandler}
          options={MyCategories}
          filterHandler={filterHandler}
        />
      </div>
      <div className='shop-main__content'>
        <ContentLeft
          categories={categories}
          setCategories={setCategories}
          setMinPrice={setMinPrice}
          minPrice={minPrice}
          setMaxPrice={setMaxPrice}
          maxPrice={maxPrice}
        />

        <ContentRight documents={documents} loading={loading} error={error} />
      </div>
    </main>
  );
}

export default Shop;
