export const products = [
  {
    id: 1,
    rating: 4,
    name: 'Chaussures de course Air Zoom',
    price: 40,
    galleries: [
      'https://images.unsplash.com/photo-1591375462077-800a22f5fba4?q=80&w=2012&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1542291026-7eec264c27ff?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ],
    image:
      'https://images.unsplash.com/photo-1542291026-7eec264c27ff?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 2,
    rating: 3,
    name: 'Ensemble de maquillage professionnel',
    price: 18,
    galleries: [
      'https://images.unsplash.com/photo-1591375462077-800a22f5fba4?q=80&w=2012&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1542291026-7eec264c27ff?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ],
    image:
      'https://images.unsplash.com/photo-1591375462077-800a22f5fba4?q=80&w=2012&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 3,
    rating: 4,
    name: 'Sac à dos étanche Adventure',
    price: 15,
    galleries: [
      'https://images.unsplash.com/photo-1591375462077-800a22f5fba4?q=80&w=2012&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1542291026-7eec264c27ff?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ],
    image:
      'https://images.unsplash.com/photo-1591348278999-ee1d0c06ed7b?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 4,
    rating: 5,
    name: 'Montre intelligente Connect',
    price: 25,
    galleries: [
      'https://images.unsplash.com/photo-1591375462077-800a22f5fba4?q=80&w=2012&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1542291026-7eec264c27ff?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ],
    image:
      'https://images.unsplash.com/photo-1492707892479-7bc8d5a4ee93?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 5,
    rating: 5,
    name: 'Chemise en coton à manches longues',
    price: 20,
    galleries: [
      'https://images.unsplash.com/photo-1591375462077-800a22f5fba4?q=80&w=2012&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1542291026-7eec264c27ff?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ],
    image:
      'https://images.unsplash.com/flagged/photo-1564468781192-f023d514222d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 6,
    rating: 5,
    name: 'Chaussures de sport Air Max',
    price: 16,
    galleries: [
      'https://images.unsplash.com/photo-1591375462077-800a22f5fba4?q=80&w=2012&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1542291026-7eec264c27ff?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ],
    image:
      'https://images.unsplash.com/photo-1515955656352-a1fa3ffcd111?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 7,
    rating: 3,
    name: 'Ensemble de bagages élégant',
    price: 20,
    galleries: [
      'https://images.unsplash.com/photo-1591375462077-800a22f5fba4?q=80&w=2012&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1542291026-7eec264c27ff?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ],
    image:
      'https://images.unsplash.com/photo-1541377182189-74e4a4ea12e5?q=80&w=2016&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 8,
    rating: 4.5,
    name: 'Ensemble de t-shirt et short décontracté',
    price: 10,
    galleries: [
      'https://images.unsplash.com/photo-1591375462077-800a22f5fba4?q=80&w=2012&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1542291026-7eec264c27ff?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ],
    image:
      'https://images.unsplash.com/photo-1525507119028-ed4c629a60a3?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },

  {
    id: 9,
    rating: 3,
    price: 35,
    name: `Robe d'été légère`,
    galleries: [
      'https://images.unsplash.com/photo-1591375462077-800a22f5fba4?q=80&w=2012&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1542291026-7eec264c27ff?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ],
    image:
      'https://plus.unsplash.com/premium_photo-1675106697016-2b84a9f98d96?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 10,
    rating: 4,
    price: 40,
    name: `Casque bleutooth intelligent`,
    galleries: [
      'https://images.unsplash.com/photo-1591375462077-800a22f5fba4?q=80&w=2012&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1542291026-7eec264c27ff?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ],
    image:
      'https://images.unsplash.com/photo-1545127398-14699f92334b?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 11,
    rating: 5,
    price: 500,
    name: `Iphone 14 pro`,
    galleries: [
      'https://images.unsplash.com/photo-1591375462077-800a22f5fba4?q=80&w=2012&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1542291026-7eec264c27ff?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ],
    image:
      'https://images.unsplash.com/photo-1571380401583-72ca84994796?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 12,
    rating: 3,
    price: 40,
    name: `Creme de cheveux`,
    galleries: [
      'https://images.unsplash.com/photo-1591375462077-800a22f5fba4?q=80&w=2012&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1542291026-7eec264c27ff?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ],
    image:
      'https://images.unsplash.com/photo-1556229010-6c3f2c9ca5f8?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
];

export const MyCategories = [
  'Soins de la peau',
  'Maquillage',
  'Produits capillaires',
  'Parfums',
  'Produits pour le bain et le corps',
  'Soins des ongles',
  'Produits de protection solaire',
  'Produits anti-âge',
  `Produits de traitement de l'acné`,
  'Autres',
];

export const delevery = [
  '1 jour',
  '2 jours',
  'plus de 3 jours',
  'plus de 4 jours',
];

export const categories = [
  'Electronique',
  'Mode',
  'Maison et cuisine',
  'Sports et plein air',
  'Jouets et jeux',
  'Jardinage et extérieur',
  'Santé et bien-être',
];

export const sousCategories = [
  // Électronique
  [
    'Téléphones intelligents',
    'Ordinateurs portables',
    'Appareils photo',
    'Écouteurs et casques',
    'Accessoires électroniques',
  ],

  // Mode
  [
    'Vêtements pour hommes',
    'Vêtements pour femmes',
    'Chaussures',
    'Accessoires de mode',
    'Bijoux',
  ],

  // Maison et cuisine
  [
    'Ustensiles de cuisine',
    'Meubles de maison',
    'Appareils électroménagers',
    'Décoration intérieure',
    'Literie',
  ],

  // Sports et plein air
  [
    'Équipement de sport',
    'Vêtements de sport',
    'Camping et randonnée',
    'Accessoires de plein air',
    'Articles de pêche',
  ],

  // Jouets et jeux
  [
    'Jouets éducatifs',
    'Jeux de société',
    'Jouets en peluche',
    'Jeux vidéo',
    'Puzzles',
  ],

  // Jardinage et extérieur
  [
    'Outils de jardinage',
    "Mobilier d'extérieur",
    'Équipement de jardin',
    'Décoration de jardin',
    'Plantes et semences',
  ],

  // Santé et bien-être
  [
    'Vitamines et suppléments',
    'Soins de la peau',
    'Équipement médical',
    'Produits de bien-être',
    'Livres de santé',
  ],
];

export const marques = [
  ['Samsung', 'Apple', 'Sony', 'LG', 'HP'],
  ['Nike', 'Adidas', 'Zara', 'Gucci', 'H&M'],
  ['IKEA', 'KitchenAid', 'Dyson', 'Cuisinart', 'Philips'],
  ['The North Face', 'Columbia', 'Adidas Outdoor', 'Patagonia', 'Salomon'],
  ['LEGO', 'Mattel', 'Hasbro', 'Fisher-Price', 'Melissa & Doug'],
  ['Black & Decker', 'Weber', 'Scotts', 'Husqvarna', 'Miracle-Gro'],
  ['Johnson & Johnson', 'Procter & Gamble', 'Nestlé', 'GSK', 'Bayer'],
];

export const productData = [
  {
    id: 1,
    rating: 4,
    name: 'Chaussures de course Air Zoom',
    price: 40,
    image:
      'https://images.unsplash.com/photo-1542291026-7eec264c27ff?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 2,
    rating: 3,
    name: 'Ensemble de maquillage professionnel',
    price: 18,
    image:
      'https://images.unsplash.com/photo-1591375462077-800a22f5fba4?q=80&w=2012&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
];

const product = {
  id: 9,
  rating: 3,
  price: 35,
  name: `Robe d'été légère`,
  description: `Découvrez la magie de notre dernier produit de beauté, une fusion exquise de luxe et d'efficacité. Ce soin révolutionnaire redéfinit la routine de beauté quotidienne en offrant une hydratation intense, une régénération cellulaire et une protection contre les agressions extérieures. Sa formule innovante, riche en ingrédients naturels et puissants, nourrit la peau en profondeur, la laissant radieuse et éclatante. Laissez-vous emporter par son parfum envoûtant et sa texture soyeuse qui glisse délicatement sur la peau. Offrez-vous l'expérience ultime du bien-être et révélez la meilleure version de vous-même avec notre produit de beauté incontournable.`,
  composition: `Découvrez la magie de notre dernier produit de beauté, une fusion exquise de luxe et d'efficacité. Ce soin révolutionnaire redéfinit la routine de beauté quotidienne en offrant une hydratation intense, une régénération cellulaire et une protection contre les agressions extérieures. Sa formule innovante, riche en ingrédients naturels et puissants, nourrit la peau en profondeur, la laissant radieuse et éclatante. Laissez-vous emporter par son parfum envoûtant et sa texture soyeuse qui glisse délicatement sur la peau. `,
  spcifications: [
    {
      name: "Type d'animal",
      value: '400g',
    },
    {
      name: "Huile d'olive extra vierge",
      value: '400g',
    },
    {
      name: 'Miel de lavande biologique',
      value: '250g',
    },
    {
      name: 'Quinoa biologique',
      value: '500g',
    },
    {
      name: 'Farine de blé entier',
      value: '1kg',
    },
    {
      name: 'Amandes grillées',
      value: '300g',
    },
    {
      name: 'Jus de fruits naturels',
      value: '750ml',
    },
    {
      name: 'Fromage de chèvre frais',
      value: '200g',
    },
    {
      name: 'Tomates biologiques',
      value: '1kg',
    },
    {
      name: 'Mélange de fruits secs',
      value: '500g',
    },
  ],
  galleries: [
    // 'https://images.unsplash.com/photo-1515955656352-a1fa3ffcd111?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1541377182189-74e4a4ea12e5?q=80&w=2016&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1525507119028-ed4c629a60a3?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://plus.unsplash.com/premium_photo-1675106697016-2b84a9f98d96?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  ],
  image:
    'https://plus.unsplash.com/premium_photo-1675106697016-2b84a9f98d96?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
};
