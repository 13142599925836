import React, { useState } from 'react';
import { products } from '../../data/data';
import { useMyContext } from '../../context/UseContext';

const CartItem = ({ product }) => {
  return (
    <div className='cart-product__items'>
      <div className='cart-product__items-image'>
        <img
          className='cart-product__items-img'
          src={product.image}
          alt={product.name}
        />
      </div>
      <div className='cart-product__details'>
        <div className='cart-product__detail'>
          <h5 className='cart-product__detail-title'>{product.name}</h5>
          <div className='cart-product__detail-count'>
            <span className='cart-product__detail-count__descriment'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke-width='1.5'
                stroke='currentColor'
                style={{ width: '20px', height: '20px' }}
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  d='M5 12h14'
                />
              </svg>
            </span>
            <span className='cart-product__detail-count__number'>
              {product.qty}
            </span>
            <span className='cart-product__detail-count__increment'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke-width='1.5'
                stroke='currentColor'
                style={{ width: '20px', height: '20px' }}
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  d='M12 4.5v15m7.5-7.5h-15'
                />
              </svg>
            </span>
          </div>
        </div>
        <span className='cart-product__details-price'>{product.price}€</span>
      </div>
    </div>
  );
};

const CheckOutListProduct = ({ cart }) => {
  return (
    <div className='cart-product'>
      <h4 className='cart-product__title'>Products encours de commande</h4>
      {cart.items.map((product, index) => (
        <CartItem key={index} product={product} />
      ))}
    </div>
  );
};

export default CheckOutListProduct;
