import React from 'react';
import './pagination.css';

const Pagination = ({ documents, productsPerPage, currentPage, paginate }) => {
  return (
    <div className='pagination'>
      {Array.from({
        length: Math.ceil(documents.length / productsPerPage),
      }).map((_, index) => (
        <button
          className={`pagination-button ${
            index + 1 === currentPage && 'active'
          } `}
          key={index}
          onClick={() => paginate(index + 1)}
        >
          {index + 1}
        </button>
      ))}
    </div>
  );
};

export default Pagination;
