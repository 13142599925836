import React, { useState } from 'react';
import { useUserCollection } from '../../hooks/useUserCollection';
import { useMyContext } from '../../context/UseContext';
import Pagination from '../../utilities/Pagination';
import Loader from '../../utilities/Loader';
import NotproductFound from '../../utilities/NotproductFound';
import { useNavigate } from 'react-router-dom';

const ContentOrder = () => {
  const { user } = useMyContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(3);
  const navigate = useNavigate();

  const [orderTypes] = useState('orders');
  const { documents, loading, error } = useUserCollection('orders', user.uid);

  const indexOfLastProduct = currentPage * productsPerPage;

  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const currentProducts = documents.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function formatDate(timestamp) {
    const date = new Date(timestamp);

    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    const formattedDate = date.toLocaleDateString('fr-FR', options);

    return formattedDate;
  }

  return (
    <div className='content-order'>
      <div className={`content-order__btn `}>
        <button
          className={`content-order__btn-1 ${
            orderTypes === 'orders' && 'active'
          }`}
        >
          Liste des commandes
        </button>
      </div>

      {loading ? (
        <Loader />
      ) : error ? (
        <span className='error'>{error}</span>
      ) : documents.length === 0 ? (
        <NotproductFound />
      ) : (
        <ul className='content-order__items'>
          {currentProducts.map((order, index) => (
            <li key={index} className='content-order__item'>
              <div className='content-order__item-left'>
                <div className='content-order__item-left__header'>
                  <div className='content-order__item-left__header-delevery'>
                    <h5>Commande du {formatDate(order?.createdAt)}</h5>
                    <span>{order.isShipped ? 'Livré' : 'Non livré'}</span>
                  </div>
                  <div className='content-order__item-left__header-sect'>
                    <h5>Livraison</h5>
                    <span>
                      N° {order.id.substring(10, order.id.length).toUpperCase()}
                    </span>
                  </div>
                  <div className='content-order__item-left__header-sect'>
                    <h5>{order.shippingMethode}</h5>
                    <span>{order.shippingPeriod}</span>
                  </div>
                </div>
                <span
                  onClick={() => navigate('/contact')}
                  className='content-order__item-left__bottom'
                >
                  Signaler un probleme
                </span>
              </div>
              <div className='content-order__right'>
                <img src={order.cartItems[0].image} alt='image' />
                <span className='content-order__right__price'>
                  {order.totalPrice} €
                </span>
              </div>
            </li>
          ))}
        </ul>
      )}

      <Pagination
        documents={documents}
        productsPerPage={productsPerPage}
        currentPage={currentPage}
        paginate={paginate}
      />
    </div>
  );
};

export default ContentOrder;
