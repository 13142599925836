import React from 'react';
import { Link } from 'react-router-dom';
import './header.css';
import PrimaryBtn from '../PrimaryBtn';
import { useMyContext } from '../../context/UseContext';

const Header = () => {
  const { user } = useMyContext();

  return (
    <>
      <div className='header'>
        <div className='header-left'>
          <ul className='header-left__list'>
            <li className='header-left__list-el'>
              <span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  style={{ height: '18px', width: '18px' }}
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z'
                  />
                </svg>
              </span>

              <span>+33 7 83 84 66 89</span>
            </li>
            <li className='header-left__list-el'>
              <span style={{ display: 'flex', alignItems: 'end' }}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  style={{ height: '18px', width: '18px' }}
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75'
                  />
                </svg>
              </span>

              <span>hemmycontact@gmail.com</span>
            </li>
          </ul>
        </div>
        <ul className='header-right'>
          <li className='header-right__list'>
            <Link className='header-right__list-link' to={'/contact'}>
              Contactez-nous
            </Link>
          </li>
          {!user && (
            <li className='header-right__list'>
              <Link className='header-right__list-link' to={'/connexion'}>
                Connexion
              </Link>
            </li>
          )}

          <li className='header-right__list'>
            <Link className='header-right__list-link' to={'/faqs'}>
              FAQs
            </Link>
          </li>
        </ul>
      </div>
      <div className='mobile-header__container'>
        <ul className='mobile-header'>
          <li className='mobile-header__list'>
            <span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                style={{ height: '18px', width: '18px' }}
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z'
                />
              </svg>
            </span>

            <span>+33 7 83 84 66 89</span>
          </li>

          <li className='mobile-header__list'>
            <span style={{ display: 'flex', alignItems: 'end' }}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                style={{ height: '18px', width: '18px' }}
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75'
                />
              </svg>
            </span>
            <span>hemmycontact@gmail.com</span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Header;
