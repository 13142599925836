import React, { useState } from 'react';
import { MyCategories, delevery } from './../../data/data';

function ContentLeft({
  maxPrice,
  categories,
  setCategories,
  setMinPrice,
  minPrice,
  setMaxPrice,
}) {
  const [selectedMarques, setSelectedMarques] = useState([]);
  const [deleveryDays, setDeleveryDays] = useState([]);

  const handleCheckboxChange = (cat) => {
    if (categories.includes(cat)) {
      setCategories((prevSelected) =>
        prevSelected.filter((selected) => selected !== cat)
      );
    } else {
      setCategories((prevSelected) => [...prevSelected, cat]);
    }
  };

  const handleCheckboxDaysChange = (delever) => {
    if (deleveryDays.includes(delever)) {
      setDeleveryDays((prevSelected) =>
        prevSelected.filter((selected) => selected !== delever)
      );
    } else {
      setDeleveryDays((prevSelected) => [...prevSelected, delever]);
    }
  };

  const minPriceHandleChange = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, '');
    // Met à jour la valeur dans le state
    setMinPrice(numericValue);
  };

  const maxPriceHandleChange = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, '');

    setMaxPrice(numericValue);
  };
  return (
    <div className='content-left'>
      <div>
        <h4 className='content-left__title'>Prix, €</h4>
        <div className='content-left__els-price'>
          <div className='content-left__els-price__from'>
            <span>De</span>
            <input
              type='text'
              value={minPrice}
              onChange={minPriceHandleChange}
            />
          </div>
          <div className='content-left__els-price__before'>
            <span>A</span>
            <input
              type='text'
              value={maxPrice}
              onChange={maxPriceHandleChange}
            />
          </div>
        </div>
      </div>
      <div>
        <h4 className='header-filter__title'>Catégories</h4>
        <ul className='content-left__els'>
          {MyCategories.map((souCategorie) => (
            <li className='content-left__el' key={souCategorie}>
              <label>
                <input
                  type='checkbox'
                  checked={categories.includes(souCategorie)}
                  onChange={() => handleCheckboxChange(souCategorie)}
                />
                {souCategorie}
              </label>
            </li>
          ))}
        </ul>
      </div>
      {/* <div>
        <h4 className='content-left__title'>Temps de livraison</h4>
        <ul className='content-left__els'>
          {delevery.map((delever) => (
            <li className='content-left__el' key={delever}>
              <label>
                <input
                  type='checkbox'
                  // checked={selectedMarques.includes(delever)}
                  onChange={() => handleCheckboxDaysChange(delever)}
                />
                {delever}
              </label>
            </li>
          ))}
        </ul>
      </div> */}
    </div>
  );
}

export default ContentLeft;
