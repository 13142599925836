import React from 'react';
import './myorders.css';
import ContentOrder from '../../components/myorders/ContentOrder';
import Layout from '../../components/admin/Layout';

const MyOrders = () => {
  return (
    <div className='my-orders'>
      <Layout>
        <div>
          <ContentOrder />
        </div>
      </Layout>
    </div>
  );
};

export default MyOrders;
