import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ban1 from '../../../src/public/images/ban1.png';
import ban2 from '../../../src/public/images/ban2.png';
import ban3 from '../../../src/public/images/ban3.png';

const images = [
  {
    url: '/produit/ofJUzIkIn3vYnFovtkVG',
    imgsrc: ban1,
  },
  {
    url: '/produit/nNsi9FZDEXzJChwLsOXX',
    imgsrc: ban2,
  },
  {
    url: '/produit/Bwle5f5NTwW2wwpUyMiL',
    imgsrc: ban3,
  },
];

const HeaderSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => Number(prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className='headerslider'>
      {images.length > 0 && (
        <>
          <Link to={images[currentIndex].url}>
            <img
              src={images[currentIndex].imgsrc}
              alt={`Slide ${currentIndex + 1}`}
              className='headerslider-img'
            />
          </Link>
          <button
            onClick={prevSlide}
            style={{
              position: 'absolute',
              top: '50%',
              left: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 10,
              cursor: 'pointer',
              border: 'none',
              background: 'transparent',
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              style={{ width: '30px', height: '30px', color: '#510187' }}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M15.75 19.5 8.25 12l7.5-7.5'
              />
            </svg>
          </button>
          <button
            onClick={nextSlide}
            style={{
              position: 'absolute',
              top: '50%',
              right: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 10,
              cursor: 'pointer',
              border: 'none',
              background: 'transparent',
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              style={{ width: '30px', height: '30px', color: '#510187' }}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='m8.25 4.5 7.5 7.5-7.5 7.5'
              />
            </svg>
          </button>
          <div
            style={{
              position: 'absolute',
              bottom: '10px',
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
            }}
          >
            {images.map((_, index) => (
              <div
                key={index}
                style={{
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  background: currentIndex === index ? '#4b02a4' : '#bda3ce',
                  margin: '0 10px',
                  cursor: 'pointer',
                }}
                onClick={() => setCurrentIndex(index)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default HeaderSlider;
