import React, { useEffect, useState } from 'react';
import './cart.css';
import { useFireStore } from '../hooks/useFireStore';
import { useMyContext } from '../context/UseContext';
import { usePaypal } from '../hooks/usePaypal';
import CheckOutDetailsOrder from '../components/checkout/CheckOutDetailsOrder';
import CheckOutPayemenMethode from '../components/checkout/CheckOutPayemenMethode';
import CheckOutShippingMethode from '../components/checkout/CheckOutShippingMethode';
import CheckOutListProduct from '../components/checkout/CheckOutListProduct';
import { useParams } from 'react-router-dom';
import { useDocument } from '../hooks/useDocument';
import { addDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import { toast } from 'react-toastify';

const CheckOut = () => {
  const { cart, user, cartDispatch } = useMyContext();
  const { id } = useParams();
  const { docResponse } = useDocument('orders', id);
  const { document, isPending, error: docError, success } = docResponse;
  usePaypal(document);

  console.log(document);

  const [shippingMethode, setShippingMethode] = useState('');
  const [shippingAddresse, setShippingAddresse] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [payementMethode, setPayementMethode] = useState('');
  const [customName, setCustomName] = useState('');

  useEffect(() => {
    if (success) {
      setShippingMethode(document.shippingMethode);
      setShippingAddresse(document.shippingAddresse);
      setPhoneNumber(document.phoneNumber);
      setPayementMethode(document.payementMethode);
      setCustomName(document.customName);
    }
  }, [success]);

  const shippingPeriod = () => {
    const today = new Date();

    const deliveryDate = new Date(today);
    deliveryDate.setDate(today.getDate() + 4);

    const formattedToday = formatDate(today);
    const formattedDeliveryDate = formatDate(deliveryDate);

    return `${formattedToday} - ${formattedDeliveryDate}`;
  };

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return date.toLocaleDateString('fr-FR', options);
  };

  function formDate(date) {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return date.toLocaleDateString('fr-FR', options);
  }

  const submitHandler = async (e) => {
    e.preventDefault();
  };

  function createOrder(data, actions) {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: Number(document.totalPrice),
            },
          },
        ],
      })
      .then((orderId) => {
        return orderId;
      });
  }
  function onApprove(data, actions) {
    return actions.order.capture().then(async function (details) {
      try {
        const docRef = doc(db, 'orders', id);
        const ref = doc(db, 'messages', id);
        cartDispatch({ type: 'CLEAR_CART' });
        await Promise.all([
          updateDoc(docRef, {
            ...document,
            cartItems: [...document.cartItems],
            email_address: details.email_address,
            isPaid: true,
          }),

          addDoc(ref, {
            title: `Commeande de  ${document.customName}`,
            message: `Nouvelle commande reçue de ${document.customName} (${document.phoneNumber}).
              Veuillez traiter rapidement. Merci pour votre attention.`,
            isRead: false,
            email: details.email_address,
            campany: '',
            name: document.customName,
            phoneNumber: document.phoneNumber,
          }),
        ]);
      } catch (err) {
        toast.error(`Une erreur s'est produite`, {
          position: 'top-center',
        });
        console.log(err);
      }
    });
  }

  function onError(err) {
    console.log(err);
  }

  return (
    <main className='cart'>
      <h4 className='cart-title'>Panier</h4>
      <div className='cart-grid'>
        <div style={{ boxSizing: 'border-box' }}>
          <CheckOutListProduct cart={cart} />
          <CheckOutShippingMethode
            shippingMethode={shippingMethode}
            shippingAddresse={shippingAddresse}
            phoneNumber={phoneNumber}
            customName={customName}
          />
          <CheckOutPayemenMethode
            methodeMethode={payementMethode}
            setMethodeMethode={setPayementMethode}
          />
        </div>
        <div>
          <CheckOutDetailsOrder
            phoneNumber={phoneNumber}
            shippingAddresse={shippingAddresse}
            shippingPeriod={shippingPeriod}
            payementMethode={payementMethode}
            formatDate={formDate}
            submitHandler={submitHandler}
            isPending={isPending}
            document={document}
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onError}
          />
        </div>
      </div>
    </main>
  );
};

export default CheckOut;
