import React from 'react';
import SelectInput from '../../utilities/SelectInput';
import { MyCategories } from '../../data/data';
const short = ['Croissant', 'Décroissant'];

const MobileHeader = ({ filterHandler, value, onChange }) => {
  return (
    <div className='mobile__header-select'>
      <div className='mobile__header-select__el'>
        <h5>Catégories</h5>
        <SelectInput
          value={value}
          onChange={onChange}
          text={'Catégories'}
          options={MyCategories}
        />
      </div>
      <div className='mobile__header-select__el'>
        <h5>Trie</h5>
        <SelectInput
          value={value}
          onChange={filterHandler}
          text={'Trie par'}
          options={short}
        />
      </div>
    </div>
  );
};

export default MobileHeader;
