import React from 'react';
import Layout from '../../components/admin/Layout';
import './account.css';
import { useMyContext } from '../../context/UseContext';
import { useNavigate } from 'react-router-dom';

const Account = () => {
  const { profil } = useMyContext();
  const navigate = useNavigate();

  return (
    <Layout>
      <div className='main-account'>
        <div className='main-account__img'>
          <img src={profil?.photo} alt='image' />
        </div>
        <div className='main-account__description'>
          <h4>{profil?.name}</h4>
          <ul className='main-account__description-items'>
            <li className='main-account__description-item'>
              <span>Date de naissance</span>
              <span>{profil?.dateDeNaissance}</span>
            </li>
            <li className='main-account__description-item'>
              <span>Téléphone</span>
              <span>{profil?.phone}</span>
            </li>
            <li className='main-account__description-item'>
              <span>Email</span>
              <span>{profil?.email}</span>
            </li>
          </ul>
        </div>
        <button
          onClick={() => navigate('/admin/parametre-compte')}
          className='main-account__btn'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            style={{ height: '25px', width: '25px' }}
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125'
            />
          </svg>
        </button>
      </div>
    </Layout>
  );
};

export default Account;
