import React, { useState } from 'react';
import ProductCard from '../utilities/ProductCard';
import './search.css';
import { useCollection } from '../hooks/useCollection';
import { liked, noLiked } from '../utils/likeHeart';
import Pagination from '../utilities/Pagination';
import Loader from '../utilities/Loader';
import { Link, useLocation } from 'react-router-dom';

const Search = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const keyword = searchParams.get('keyword');

  const { documents, loading } = useCollection(
    'products',
    null,
    null,
    keyword,
    null,
    null
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(6);

  const indexOfLastProduct = currentPage * productsPerPage;

  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const currentProducts = documents.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <main className='search'>
      {documents.length > 0 && (
        <h1 className='search-title'>Resultat de recherche pour:{keyword}</h1>
      )}

      {loading ? (
        <Loader />
      ) : documents.length === 0 ? (
        <div className='search-empty__container'>
          <div className='search-empty'>
            <h2>Aucun produit trouvé</h2>
            <p>Désolé, aucun produit ne correspond au mot-clé saisi.</p>
            <Link to={'/boutique'}>
              <a className='btn-back-to-shop'>Retour à la boutique</a>
            </Link>
          </div>
        </div>
      ) : (
        <div className='search__cards'>
          {currentProducts.map((product, index) => (
            <ProductCard
              liked={liked}
              noLiked={noLiked}
              product={product}
              index={index}
              key={index}
            />
          ))}
        </div>
      )}

      <Pagination
        documents={documents}
        productsPerPage={productsPerPage}
        currentPage={currentPage}
        paginate={paginate}
      />
    </main>
  );
};

export default Search;
