import React from 'react';
import './policy.css';

const Policy = () => {
  return (
    <main className='privacy-policy-container'>
      <div className='privacy-policy-container__header'>
        <h2>Politique de confidentialité</h2>
        <p>
          Votre vie privée est extrêmement importante pour nous. Cette politique
          de confidentialité explique quelles informations personnelles nous
          collectons et comment nous les utilisons.
        </p>
      </div>
      <ul className='policy-list'>
        <li>
          <h3>Collecte des informations</h3>
          <p>
            Nous collectons des informations lorsque vous créez un compte,
            passez une commande, participez à un sondage ou vous abonnez à notre
            newsletter. Ces informations peuvent inclure votre nom, votre
            adresse e-mail, votre adresse postale, votre numéro de téléphone et
            d'autres informations.
          </p>
        </li>
        <li>
          <h3>Utilisation des informations</h3>
          <p>
            Les informations que nous collectons auprès de vous peuvent être
            utilisées pour :
          </p>
        </li>
      </ul>

      <ul>
        <li>
          Personnaliser votre expérience utilisateur et répondre à vos besoins
          individuels.
        </li>
        <li>
          Améliorer notre site web en fonction des commentaires que vous nous
          fournissez.
        </li>
        <li>Améliorer notre service client et vos besoins de support.</li>
        <li>Vous contacter par e-mail.</li>
      </ul>
      <ul className='policy-list'>
        <li>
          <h3>Protection des informations</h3>
          <p>
            Nous mettons en œuvre une variété de mesures de sécurité pour
            garantir la sécurité de vos informations personnelles lorsque vous
            passez une commande ou saisissez, soumettez ou accédez à vos
            informations personnelles.
          </p>
        </li>
        <li>
          <h3>Divulgation à des tiers</h3>
          <p>
            Nous ne vendons, n'échangeons et ne transférons pas vos informations
            personnelles identifiables à des tiers. Cela n'inclut pas les tiers
            de confiance qui nous aident à exploiter notre site web, à mener nos
            affaires ou à vous servir, tant que ces parties conviennent de
            garder ces informations confidentielles.
          </p>
        </li>
        <li>
          <h3>Consentement</h3>
          <p>
            En utilisant notre site, vous consentez à notre politique de
            confidentialité en ligne.
          </p>
        </li>
        <li>
          <h3>Mises à jour de la politique de confidentialité</h3>
          <p>
            Si nous décidons de changer notre politique de confidentialité, nous
            publierons ces changements sur cette page.
          </p>
        </li>
      </ul>

      <p>Dernière mise à jour : 19/02/2024</p>
    </main>
  );
};

export default Policy;
