import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';
import logo from './../../public/images/logo.png';

const Footer = () => {
  return (
    <footer className='footer'>
      <nav className='footer-nav'>
        <div className='footer-nav__container'>
          <h4
            className='footer-nav__container-title '
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <img style={{ height: '50px', marginRight: '1px' }} src={logo} />
            <span className='bottom-logo'>Hemmy</span>
          </h4>
          <p>
            Explorez notre large sélection de produits cosmétiques de qualité
            supérieure, adaptés à tous les besoins.
          </p>
        </div>
        <div className='footer-nav__container'>
          <h4 className='footer-nav__container-title'>Entreprise</h4>
          <ul className='footer-nav__container-items'>
            {/* <li className='footer-nav__container-item'>
              <Link
                className='footer-nav__container-item__link'
                to={'/apropos-de-nous'}
              >
                Qui sommes nous?
              </Link>
            </li> */}
            <li className='footer-nav__container-item'>
              <Link
                className='footer-nav__container-item__link'
                to={'/contact'}
              >
                Contactez-nous
              </Link>
            </li>
            <li className='footer-nav__container-item'>
              <Link className='footer-nav__container-item__link' to={'/faqs'}>
                Faqs
              </Link>
            </li>
          </ul>
        </div>
        <div className='footer-nav__container'>
          <h4 className='footer-nav__container-title'>Liens utitiles</h4>
          <ul className='footer-nav__container-items'>
            <li className='footer-nav__container-item'>
              <Link
                className='footer-nav__container-item__link'
                to={'/plitique-de-confidentialite'}
              >
                Politique de confidentialité
              </Link>
            </li>
            <li className='footer-nav__container-item'>
              <Link
                className='footer-nav__container-item__link'
                to={'/condition-generale-de-vente'}
              >
                Conditions générales de vente
              </Link>
            </li>
            <li className='footer-nav__container-item'>
              <Link
                className='footer-nav__container-item__link'
                to={'/boutique'}
              >
                Boutique
              </Link>
            </li>
          </ul>
        </div>
        <div className='footer-nav__container'>
          <h4 className='footer-nav__container-title'>Reseau sociaux</h4>
          <ul className='footer-medias'>
            <li className='footer-media'>
              <Link
                to='https://www.facebook.com/profile.php?id=100094185754724'
                target='_blank'
                rel='noopener noreferrer'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 320 512'
                  style={{ height: '25px', width: '25px', color: '#FFFFFF' }}
                  // fill='#FFFFFF'
                >
                  <path d='M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z' />
                </svg>
              </Link>
            </li>
            <li className='footer-media'>
              <Link
                to='https://www.tiktok.com/@hemmylayone'
                target='_blank'
                rel='noopener noreferrer'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 448 512'
                  style={{ height: '25px', width: '25px', color: '#FFFFFF' }}
                >
                  <path d='M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z' />
                </svg>
              </Link>
            </li>
            <li className='footer-media'>
              <Link
                to='https://www.instagram.com/hemmylayone05?utm_source=qr&igsh=b3Fsb2Q5cmNmYWd0'
                target='_blank'
                rel='noopener noreferrer'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 448 512'
                  style={{ height: '25px', width: '25px', color: '#FFFFFF' }}
                >
                  <path d='M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z' />
                </svg>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
