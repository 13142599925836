import React from 'react';
import './notFound.css';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <main className='not-found-container'>
      <h1>404</h1>
      <h2>Page non trouvée</h2>
      <p>Désolé, la page que vous recherchez n'existe pas.</p>

      <Link to='/'>Retour à l'accueil</Link>
    </main>
  );
};

export default NotFound;
