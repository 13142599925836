import React from 'react';
import './layout.css';
import { Link, useLocation } from 'react-router-dom';
import { useMyContext } from '../../context/UseContext';
import { useAuth } from '../../hooks/useAuth';
import { useCollection } from '../../hooks/useCollection';

const Layout = ({ children }) => {
  const location = useLocation();
  const { profil } = useMyContext();
  const { logout } = useAuth();
  const { documents } = useCollection('messages');

  const messages = documents?.filter((item) => item?.isRead === false);

  const title =
    location.pathname === '/admin/my-orders'
      ? 'Mes commandes'
      : location.pathname === '/admin/account'
      ? 'Données personnelles'
      : location.pathname === '/admin/admin/produits'
      ? 'Commandes'
      : location.pathname === '/admin/notifications'
      ? 'Notifications'
      : location.pathname === '/admin/parametre-compte'
      ? 'Parametre compte'
      : location.pathname === '/admin/new/product'
      ? 'Nouveau produit'
      : location.pathname === '/admin/produits'
      ? 'Produits'
      : '';

  return (
    <main className='layout'>
      <h4 className='layout-title'>{title}</h4>
      <div className='layout-container'>
        <div className='layout-container-body'>
          <div className='layout-container-els'>
            <ul className='layout-container-els__items'>
              <li className='layout-container-els__item'>
                <Link
                  className={`layout-container-els__item-link ${
                    location.pathname === '/admin/account' && 'active'
                  }`}
                  to={'/admin/account'}
                >
                  Données personnelles
                </Link>
              </li>

              <li className='layout-container-els__item'>
                <Link
                  className={`layout-container-els__item-link ${
                    location.pathname === '/admin/my-orders' && 'active'
                  }`}
                  to={'/admin/my-orders'}
                >
                  Mes commandes
                </Link>
              </li>
              {profil && profil.isAdmin && (
                <>
                  <li className='layout-container-els__item'>
                    <Link
                      className={`layout-container-els__item-link ${
                        (location.pathname === '/admin/produits' ||
                          location.pathname === '/admin/new/product') &&
                        'active'
                      }`}
                      to={'/admin/produits'}
                    >
                      Produits
                    </Link>
                  </li>
                  <li
                    style={{ position: 'relative' }}
                    className='layout-container-els__item'
                  >
                    {messages.length > 0 && (
                      <span
                        style={{
                          display: 'flex',
                          position: 'absolute',
                          right: '10px',
                          top: '0px',
                          backgroundColor: '#fe0000',
                          height: '25px',
                          width: '25px',
                          color: '#fff',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '400',
                          borderRadius: '50%',
                        }}
                      >
                        {messages.length}
                      </span>
                    )}

                    <Link
                      className={`layout-container-els__item-link ${
                        location.pathname === '/admin/notifications' && 'active'
                      }`}
                      to={'/admin/notifications'}
                    >
                      Notifications
                    </Link>
                  </li>

                  <li className='layout-container-els__item'>
                    <Link
                      className={`layout-container-els__item-link ${
                        location.pathname === '/admin/commandes' && 'active'
                      }`}
                      to={'/admin/commandes'}
                    >
                      Commandes
                    </Link>
                  </li>
                </>
              )}

              <li className='layout-container-els__item'>
                <Link
                  className={`layout-container-els__item-link ${
                    location.pathname === '/admin/parametre-compte' && 'active'
                  }`}
                  to={'/admin/parametre-compte'}
                >
                  Parametres du compte
                </Link>
              </li>
            </ul>
            <div className='layout-container-els__bottom'>
              <button
                onClick={logout}
                className='layout-container-els__bottom__btn'
              >
                Déconnexion
              </button>
            </div>
          </div>
        </div>
        {children}
      </div>
    </main>
  );
};

export default Layout;
