import { createContext, useContext, useEffect, useReducer } from 'react';
import { auth, db } from '../firebase/config';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { authReducer, cartReducer } from '../reducer/reducer';

export const UseContext = createContext();

const initialCartState = {
  items: [],
  totalPrice: 0,
  message: null,
};

export const UseContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
    profil: null,
  });

  const [cart, cartDispatch] = useReducer(cartReducer, initialCartState);

  useEffect(() => {
    const isAuthenticated = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          const docRef = doc(db, 'users', user.uid);
          const profil = await getDoc(docRef);
          dispatch({
            type: 'AUTH_IS_READY',
            payload: { user, profil: profil.data() },
          });
        } else {
          dispatch({
            type: 'AUTH_IS_READY',
            payload: { user: null, profil: null },
          });
        }
      } catch (err) {
        console.error(err);
      }
    });

    return () => isAuthenticated();
  }, [state.user]);

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem('cartItems'));
    if (cart) {
      cartDispatch({ type: 'EXIST_CART', payload: cart });
    }
  }, []);

  return (
    <UseContext.Provider value={{ ...state, dispatch, cart, cartDispatch }}>
      {children}
    </UseContext.Provider>
  );
};

export const useMyContext = () => {
  const context = useContext(UseContext);
  if (!context) {
    throw new Error('You must provide');
  }
  return context;
};
