import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  uploadBytesResumable,
} from 'firebase/storage';
import { storage } from '../firebase/config';
import { useState } from 'react';

// export const useStorage = async () => {
//   const [uploadErr, setUploadErr] = useState('');
//   const [successfully, setSuccessfully] = useState('');
//   const [image, setImage] = useState([]);

//   const uploadFile = async (file, user) => {
//     // const file = e.target.files[0];
//     try {
//       if (!file) {
//         setUploadErr('Aucun fichier sélectionné');
//         return;
//       }

//       const uploadPath = `images/${user.uid}/${file.name}`;
//       const storageRef = ref(storage, uploadPath);

//       // Upload file to Firebase Storage
//       await uploadBytes(storageRef, file);
//       const imgUrl = await getDownloadURL(storageRef);
//       setImage(imgUrl);

//     } catch (err) {
//       console.error(err);
//     }
//   };

//   return { image, uploadFile, uploadErr,  setUploadErr };
// };

export const useStorage = () => {
  const [progress, setProgress] = useState(0);
  const [uploadErr, setUploadErr] = useState('');
  const [successfully, setSuccessfully] = useState('');
  const [success, setSucces] = useState(false);
  const [images, setImages] = useState([]);
  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isdeletd, setIsdeletd] = useState(false);

  let uploadedImages = [];
  const uploadFiles = async (files, user) => {
    setSucces(false);
    try {
      if (!files || files.length === 0) {
        setUploadErr('Aucun fichier sélectionné');
        return;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        const uploadPath = `images/${user.uid}/${file.name}`;
        const storageRef = ref(storage, uploadPath);

        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(progress);
          },
          (error) => {
            console.error(error.message);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            uploadedImages.push(downloadURL);
            if (uploadedImages.length === files.length) {
              setTimeout(() => {
                setProgress(0);
              }, 3000);
              setImages(uploadedImages);
              setSucces(true);
            }
          }
        );
      }
    } catch (err) {
      setUploadErr('Erreur de téléchargement');
      setSucces(false);
      console.error(err);
    }
  };

  const uploadFile = async (file, user) => {
    try {
      if (!file) {
        setUploadErr('Aucun fichier sélectionné');
        return;
      }

      setIsLoading(true);

      const uploadPath = `profil/${user.uid}/${file.name}`;
      const storageRef = ref(storage, uploadPath);

      await uploadBytes(storageRef, file);
      const imgUrl = await getDownloadURL(storageRef);
      setImage(imgUrl);
      setIsLoading(false);
    } catch (err) {
      setUploadErr('Erreur de téléchargement');
      console.error(err);
    }
  };

  const deleteFile = async (fileRef) => {
    const desertRef = ref(storage, fileRef);
    setIsdeletd(false);
    try {
      await deleteObject(desertRef);
      const imgs = images.filter((i) => i !== fileRef);
      setImages(imgs);
      setSuccessfully('Fichier supprimé avec succès');
      setIsdeletd(true);
    } catch (error) {
      setUploadErr('Erreur lors de la suppression');
    }
  };

  return {
    images,
    progress,
    image,
    uploadFiles,
    uploadFile,
    uploadErr,
    deleteFile,
    setUploadErr,
    successfully,
    isLoading,
    isdeletd,
    success,
  };
};
