import React, { useEffect, useState } from 'react';
import Layout from '../../components/admin/Layout';
import InputFied from '../../utilities/InputFied';
import './accountsetting.css';
import FourthButton from '../../utilities/FourthButton';
import { useMyContext } from '../../context/UseContext';
import { useDocument } from '../../hooks/useDocument';
import { useStorage } from '../../hooks/useStorage';
import { useFireStore } from '../../hooks/useFireStore';
import { updateProfile } from 'firebase/auth';
import BarLoader from 'react-spinners/BarLoader';
import { useNavigate } from 'react-router-dom';

const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#4b02a4',
  width: '100%',
};

const AccountSetting = () => {
  const navigate = useNavigate();
  const { user, profil, dispatch } = useMyContext();
  const { response, updateDocument } = useFireStore('users');
  const [error, setError] = useState('');
  const [pending, setPending] = useState(false);

  const {
    image,
    progress,
    uploadFile,
    uploadErr,
    deleteFile,
    setUploadErr,
    isLoading,
  } = useStorage();

  const [name, setName] = useState('');
  const [dateDeNaissance, setDateDeNaissance] = useState('');

  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [photo, setPhoto] = useState(image);

  useEffect(() => {
    if (profil != null) {
      setName(profil.name);
      setDateDeNaissance(profil.dateDeNaissance);
      setPhone(profil.phone);
      setEmail(profil.email);
      setPhoto(profil.photo);
    }
  }, [profil != null]);

  useEffect(() => {
    if (image) {
      setPhoto(image);
    }
  }, [image]);

  const handleFileChange = (event) => {
    uploadFile(event.target.files[0], user);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      setPending(true);
      await updateProfile(user, {
        displayName: name,
        photoURL: photo,
        phoneNumber: phone,
      });
      const doc = {
        name,
        dateDeNaissance,
        phone,
        email,
        photo,
      };
      await updateDocument(user.uid, doc);
      dispatch({
        type: 'AUTH_IS_READY',
        payload: { user, profil: doc },
      });
      setPending(false);
      navigate('/admin/account');
    } catch (error) {
      setPending(false);
      setError('Erreur de mise a jour');
      console.log(error);
    }
  };

  return (
    <Layout>
      <div>
        {' '}
        <BarLoader
          color='#4b02a4'
          loading={pending}
          cssOverride={override}
          size={150}
          data-testid='loader'
        />
        {error && <span className='error'>{error}</span>}
        <div className='account-setting'>
          <div className='account-setting__img'>
            {photo ? (
              <img src={photo} alt='' />
            ) : (
              <div className='account-setting__img-null'></div>
            )}

            <label>
              <input
                type='file'
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <button
                onClick={() =>
                  document.querySelector('input[type=file]').click()
                }
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  style={{ height: '20px', width: '20px' }}
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125'
                  />
                </svg>
              </button>
            </label>
          </div>
          <form
            onSubmit={submitHandler}
            action=''
            className='account-setting__form'
          >
            <InputFied
              onChange={(e) => setName(e.target.value)}
              value={name}
              text='Entrer votre nom'
              type='text'
            />
            <InputFied
              onChange={(e) => setDateDeNaissance(e.target.value)}
              value={dateDeNaissance}
              text='Entrer date de naissance'
              type='date'
            />
            <InputFied
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              text='Entrer votre numero de téléphone'
              type='text'
            />
            <InputFied
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              text='Entrer votre email'
              type='email'
            />
            <FourthButton>Mettre à jour</FourthButton>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default AccountSetting;
