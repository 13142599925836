import { cartUtilies } from '../utils/cartUtilies';

export function cartReducer(state, action) {
  let updatedItems;
  let updatedPrice;
  let cartMessage;

  switch (action.type) {
    case 'ADD_TO_CART':
      const existingItemIndex = state.items.findIndex(
        (item) => item.id === action.payload.id
      );

      if (existingItemIndex !== -1) {
        updatedItems = [...state.items];
        updatedItems[existingItemIndex].qty = action.payload.qty;
        cartMessage = 'Votre panier a été mise a jour';
      } else {
        updatedItems = [...state.items, action.payload];
        cartMessage = 'Produit ajouté au panier';
      }

      updatedPrice = cartUtilies(updatedItems);

      return {
        ...state,
        items: updatedItems,
        totalPrice: updatedPrice,
        message: cartMessage,
      };

    case 'REMOVE_FROM_CART':
      updatedItems = state.items.filter(
        (item) => item.id !== action.payload.id
      );
      updatedPrice = cartUtilies(updatedItems);
      cartMessage = 'Produit supprimé du panier';
      return {
        ...state,
        items: updatedItems,
        totalPrice: updatedPrice,
        message: cartMessage,
      };
    case 'EXIST_CART':
      return {
        ...state,
        items: action.payload.items,
        totalPrice: action.payload.totalPrice,
        message: null,
      };
    case 'INCREMENT_CART':
      const existItems = state.items.map((item) =>
        item.id === action.payload.id ? { ...item, qty: item.qty + 1 } : item
      );
      updatedPrice = cartUtilies(existItems);

      return {
        ...state,
        items: existItems,
        totalPrice: updatedPrice,
        message: null,
      };
    case 'DECRIMENT_CART':
      const items = state.items.map((item) =>
        item.id === action.payload.id
          ? { ...item, qty: item.qty > 1 ? item.qty - 1 : item.qty }
          : item
      );
      updatedPrice = cartUtilies(items);

      return {
        ...state,
        items: items,
        totalPrice: updatedPrice,
        message: null,
      };
    case 'CLEAR_CART':
      localStorage.removeItem('cartItems');
      return { items: [], totalPrice: 0 };

    default:
      return state;
  }
}

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, user: action.payload };
    case 'LOGOUT':
      return { ...state, user: null, profil: null };
    case 'AUTH_IS_READY':
      return {
        ...state,
        user: action.payload.user,
        profil: action.payload.profil,
        authIsReady: true,
      };
    default:
      return state;
  }
};
