export const cartUtilies = (cartItems) => {
  let totalPrice = 0;
  cartItems.forEach((item) => {
    totalPrice += item.price * item.qty;
  });

  localStorage.setItem(
    'cartItems',
    JSON.stringify({ items: cartItems, totalPrice: totalPrice })
  );
  return totalPrice;
};
