import React from 'react';
import DotLoader from 'react-spinners/DotLoader';
const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#4b02a4',
  // width: '100%',
};

const Loader = () => {
  return (
    <DotLoader
      color='#4b02a4'
      loading={true}
      cssOverride={override}
      size={60}
      data-testid='loader'
    />
  );
};

export default Loader;
