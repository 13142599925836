import React from 'react';
import RatingStars from '../../utilities/RatingStars';

const CommentList = ({ document }) => {
  return (
    <div>
      {document.map((comment) => (
        <div className='commentlist'>
          <div className='commentlist-users'>
            <div className='commentlist-users__infos'>
              <img
                className='commentlist-users__infos-img'
                src={comment.profil}
                alt=''
              />
              <span className='commentlist-users__infos-name'>
                {comment.name}
              </span>
            </div>
            <div className='commentlist-mobile__reating'>
              <span className='commentlist-mobile__date'>
                22/03/2022, 18:08
              </span>
              <RatingStars rating={comment.rate} />
            </div>
            <p className='commentlist-users_text'>{comment.text}</p>
          </div>
          <div className='commentlist-reating'>
            <span className='commentlist-reating__date'>22/03/2022, 18:08</span>
            <RatingStars rating={comment.rate} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CommentList;
