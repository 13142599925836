import React, { useEffect, useState } from 'react';
import './cart.css';
import DetailsOrder from '../components/cart/DetailsOrder';
import ListProduct from '../components/cart/ListProduct';
import PayemenMethode from '../components/cart/PayemenMethode';
import ShippingMethode from '../components/cart/ShippingMethode';
import { useFireStore } from '../hooks/useFireStore';
import { useMyContext } from '../context/UseContext';
import { useNavigate } from 'react-router-dom';
import EmptyCart from '../utilities/EmptyCart';

const Cart = () => {
  const { cart, user } = useMyContext();
  const { addDocument, response } = useFireStore('orders');
  const { document, isPending, error, success } = response;
  const navigate = useNavigate();

  const [shippingMethode, setShippingMethode] = useState('Point de relais');
  const [shippingAddresse, setShippingAddresse] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [payementMethode, setPayementMethode] = useState(
    'Paiement securisé en ligne'
  );
  const [customName, setCustomName] = useState('');

  const shippingPeriod = () => {
    const today = new Date();

    const deliveryDate = new Date(today);
    deliveryDate.setDate(today.getDate() + 4);

    const formattedToday = formatDate(today);
    const formattedDeliveryDate = formatDate(deliveryDate);

    return `${formattedToday} - ${formattedDeliveryDate}`;
  };

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return date.toLocaleDateString('fr-FR', options);
  };

  function formDate(date) {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return date.toLocaleDateString('fr-FR', options);
  }

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!user) {
      navigate('/connexion', { state: { key: 'cart' } });
    } else {
      await addDocument({
        uid: user.uid,
        cartItems: cart.items,
        totalPrice: cart.totalPrice,
        shippingMethode,
        shippingAddresse,
        phoneNumber,
        payementMethode,
        shippingPeriod: shippingPeriod(),
        customName,
        isPaid: false,
        email_address: '',
        createdAt: Date.now(),
        isShipped: false,
      });
    }
  };

  useEffect(() => {
    if (success) {
      navigate(`/payement/${document}`);
    }
  }, [success]);

  return (
    <main className='cart'>
      {cart.items.length === 0 ? (
        <EmptyCart />
      ) : (
        <>
          <h4 className='cart-title'>Panier</h4>
          <div className='cart-grid'>
            <div style={{ boxSizing: 'border-box' }}>
              <ListProduct cart={cart} />
              <ShippingMethode
                shippingMethode={shippingMethode}
                setShippingMethode={setShippingMethode}
                setShippingAddresse={setShippingAddresse}
                setPhoneNumber={setPhoneNumber}
                shippingAddresse={shippingAddresse}
                phoneNumber={phoneNumber}
                customName={customName}
                setCustomName={setCustomName}
              />
              <PayemenMethode
                methodeMethode={payementMethode}
                setMethodeMethode={setPayementMethode}
              />
            </div>
            <div>
              <DetailsOrder
                phoneNumber={phoneNumber}
                shippingAddresse={shippingAddresse}
                shippingPeriod={shippingPeriod}
                payementMethode={payementMethode}
                formatDate={formDate}
                submitHandler={submitHandler}
                isPending={isPending}
                customName={customName}
              />
            </div>
          </div>
        </>
      )}
    </main>
  );
};

export default Cart;
