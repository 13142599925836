import React from 'react';
import './termsOfSale.css';

const TermsOfSale = () => {
  return (
    <main className='terms-of-sale-container'>
      <h2>Conditions de vente</h2>
      <div className='terms-content'>
        <section>
          <h3>Commandes et paiement</h3>
          <p>
            En passant une commande sur notre site, vous acceptez de payer le
            prix indiqué ainsi que les frais de livraison, le cas échéant. Les
            paiements sont traités de manière sécurisée.
          </p>
        </section>
        <section>
          <h3>Livraison</h3>
          <p>
            Nous nous efforçons de livrer les commandes dans les délais indiqués
            lors de la commande. Les retards de livraison peuvent parfois
            survenir en raison de circonstances indépendantes de notre volonté.
          </p>
        </section>
        <section>
          <h3>Retours et remboursements</h3>
          <p>
            Nous acceptons les retours et les échanges conformément à notre
            politique de retour. Les articles doivent être retournés dans leur
            état d'origine et dans les délais spécifiés.
          </p>
        </section>
        <section>
          <h3>Propriété intellectuelle</h3>
          <p>
            Tout le contenu présenté sur notre site web, y compris les textes,
            les images et les logos, est la propriété de notre entreprise et est
            protégé par les lois sur la propriété intellectuelle.
          </p>
        </section>
        <section>
          <h3>Modifications des conditions de vente</h3>
          <p>
            Nous nous réservons le droit de modifier ces conditions de vente à
            tout moment. Les modifications seront effectives dès leur
            publication sur notre site web.
          </p>
        </section>
        <section>
          <h3>Service client</h3>
          <p>
            Notre équipe du service client est disponible pour répondre à vos
            questions et vous aider avec vos préoccupations. N'hésitez pas à
            nous contacter pour toute assistance supplémentaire.
          </p>
        </section>
        <section>
          <h3>Clause de non-responsabilité</h3>
          <p>
            Bien que nous nous efforcions de maintenir les informations sur
            notre site web à jour et exactes, nous ne garantissons pas
            l'exactitude, l'exhaustivité ou la pertinence des informations
            fournies.
          </p>
        </section>
      </div>
      <p className='terms-footer'>Dernière mise à jour :19/02/2024</p>
    </main>
  );
};

export default TermsOfSale;
