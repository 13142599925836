import React, { useState } from 'react';
import Layout from '../../components/admin/Layout';
import './productlist.css';
import FourthButton from '../../utilities/FourthButton';
import { useNavigate } from 'react-router-dom';
import { useCollection } from '../../hooks/useCollection';
import { useFireStore } from '../../hooks/useFireStore';
import Pagination from '../../utilities/Pagination';
import NotproductFound from '../../utilities/NotproductFound';
import Loader from '../../utilities/Loader';

const ProductList = () => {
  const { deleteDocument } = useFireStore('products');
  const { documents, loading, error } = useCollection('products');

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(8);

  const indexOfLastProduct = currentPage * productsPerPage;

  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const currentProducts = documents.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const navigate = useNavigate();
  const clickHandler = () => {
    navigate('/admin/new/product');
  };

  async function deleteHandler(productId) {
    const confirmation = window.confirm('Voulez-vous vraiment supprimer ?');
    if (confirmation) {
      await deleteDocument(productId);
    }
  }

  return (
    <Layout>
      <div>
        <div className='list-product'>
          <div className='list-product__add'>
            <FourthButton clickHandler={clickHandler}>
              <span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke-width='1.5'
                  stroke='currentColor'
                  style={{ height: '20px', width: '20px' }}
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    d='M12 4.5v15m7.5-7.5h-15'
                  />
                </svg>
              </span>{' '}
              <span> Nouveau</span>
            </FourthButton>
          </div>
          {loading ? (
            <Loader />
          ) : error ? (
            <span className='error'>{error}</span>
          ) : documents.length === 0 ? (
            <NotproductFound />
          ) : (
            <table>
              <tr>
                <th scope='col'>ID</th>
                <th scope='col'>Image</th>
                <th scope='col'>Nom</th>
                <th scope='col'>Prix</th>
                <th scope='col'>Action</th>
              </tr>
              {currentProducts.map((products, i) => (
                <tr>
                  <th scope='row'>{products.id}</th>
                  <td>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <img
                        style={{
                          height: '50px',
                          width: '60px',
                          objectFit: 'cover',
                          borderRadius: '10px',
                        }}
                        src={products.galleries[0]}
                        alt='image'
                      />
                    </div>
                  </td>
                  <td>
                    {
                      <span>
                        {products.name.length > 10
                          ? `${products.name.substring(0, 20)}...`
                          : products.name}
                      </span>
                    }
                  </td>
                  <td>{products.price}€</td>
                  <td>
                    <span
                      onClick={() =>
                        navigate(`/admin/edict-product/${products.id}`)
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        style={{
                          height: '20px',
                          width: '20px',
                          cursor: 'pointer',
                          color: '#4b02a4',
                        }}
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10'
                        />
                      </svg>
                    </span>
                    <span onClick={() => deleteHandler(products.id)}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        style={{
                          height: '20px',
                          width: '20px',
                          cursor: 'pointer',
                          color: 'red',
                        }}
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0'
                        />
                      </svg>
                    </span>
                  </td>
                </tr>
              ))}
            </table>
          )}
        </div>
        <Pagination
          documents={documents}
          productsPerPage={productsPerPage}
          currentPage={currentPage}
          paginate={paginate}
        />
      </div>
    </Layout>
  );
};

export default ProductList;
