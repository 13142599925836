import React from 'react';
import './btn.css';

const ThirdBtn = ({ children, onClick }) => {
  return (
    <button onClick={onClick} className=' thirdBtn'>
      {children}
    </button>
  );
};

export default ThirdBtn;
