import React, { useState } from 'react';
import svg from '../../public/svg/faq.svg';
import './faqs.css';
import { useNavigate } from 'react-router-dom';

const Faqs = () => {
  const faqData = [
    {
      question: 'Quels sont les modes de paiement acceptés ?',
      answer:
        'Nous acceptons les cartes de crédit, PayPal et les virements bancaires.',
    },
    {
      question: 'Comment puis-je suivre ma commande ?',
      answer:
        'Vous pouvez suivre votre commande en vous connectant à votre compte sur notre site.',
    },
    {
      question: 'Quelle est notre politique de retour ?',
      answer:
        'Nous offrons une politique de retour de 30 jours. Consultez notre page de retour pour plus de détails.',
    },
    {
      question: `Livrez-vous à l'international ?`,
      answer: `Oui, nous livrons nos produits dans le monde entier. Veuillez consulter nos options de livraison pour plus de détails.`,
    },
    {
      question: 'Comment puis-je contacter le service clientèle ?',
      answer:
        'Vous pouvez nous contacter par e-mail à hemmycontact@gmail.com ou par téléphone au +33758061693.',
    },
    {
      question: 'Proposez-vous des échantillons gratuits ?',
      answer:
        'Nous offrons des échantillons gratuits avec certaines commandes. Consultez nos offres actuelles pour plus de détails.',
    },
  ];

  const [expandedIndex, setExpandedIndex] = useState(0);

  const handleToggle = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const navigate = useNavigate();

  return (
    <main className='faq-container'>
      <div className='faq-container__left'>
        <div>
          <img src={svg} alt='icone' />
        </div>
        <div className='faq-container__left-description'>
          <h2>Avez vous d'autres questions</h2>
          <p>
            Si vous avez d'autres questions, n'hésitez pas à nous contacter par
            e-mail. Nous sommes là pour vous aider et répondre à vos
            préoccupations.
          </p>
          <button onClick={() => navigate('/contact')}>Contactez-nous</button>
        </div>
      </div>
      <div className='faq-container__right'>
        <div className='faq-container__right-header'>
          <h2>Foire aux questions</h2>
          <p>
            La "Foire aux questions" est un espace dédié aux réponses sur les
            interrogations courantes des utilisateurs, offrant une assistance
            rapide et efficace.
          </p>
        </div>
        {faqData.map((faq, index) => (
          <div className='faq-item' key={index}>
            <div className='faq-question' onClick={() => handleToggle(index)}>
              <h4>{faq.question}</h4>
              <span
                className={`icon ${expandedIndex === index ? 'expanded' : ''}`}
              >
                {expandedIndex === index ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke-width='1.5'
                    stroke='currentColor'
                    style={{ height: '18px', width: '18px' }}
                  >
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      d='m4.5 15.75 7.5-7.5 7.5 7.5'
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    style={{ height: '18px', width: '18px' }}
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='m19.5 8.25-7.5 7.5-7.5-7.5'
                    />
                  </svg>
                )}
              </span>
            </div>
            {expandedIndex === index && (
              <p className='faq-answer'>{faq.answer}</p>
            )}
          </div>
        ))}
      </div>
    </main>
  );
};

export default Faqs;
