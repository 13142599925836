import React, { useEffect, useState } from 'react';
import ProductCard from '../../utilities/ProductCard';
import Pagination from '../../utilities/Pagination';
import NotproductFound from '../../utilities/NotproductFound';
import Loader from '../../utilities/Loader';
import { liked, noLiked } from '../../utils/likeHeart';

const ContentRight = ({ documents, loading, error }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setproductsPerPage] = useState();
  const windowWidth = window.innerWidth;

  useEffect(() => {
    const updateVisibleProducts = () => {
      if (windowWidth >= 1630) {
        setproductsPerPage(10);
      } else if (windowWidth >= 1487) {
        setproductsPerPage(8);
      } else if (windowWidth >= 1044) {
        setproductsPerPage(9);
      } else if (windowWidth >= 1000) {
        setproductsPerPage(8);
      } else {
        setproductsPerPage(6);
      }
    };

    updateVisibleProducts();
    window.addEventListener('resize', updateVisibleProducts);

    return () => {
      window.removeEventListener('resize', updateVisibleProducts);
    };
  }, [windowWidth]);

  const indexOfLastProduct = currentPage * productsPerPage;

  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const currentProducts = documents.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <div className='content-right'>
      {loading ? (
        <Loader />
      ) : error ? (
        <div className='error'>
          <span>{error}</span>
        </div>
      ) : documents.length === 0 ? (
        <NotproductFound />
      ) : (
        <div className='content-right__card'>
          {currentProducts.map((product, index) => (
            <ProductCard
              liked={liked}
              noLiked={noLiked}
              product={product}
              index={index}
              key={index}
            />
          ))}
        </div>
      )}

      <Pagination
        documents={documents}
        productsPerPage={productsPerPage}
        currentPage={currentPage}
        paginate={paginate}
      />
    </div>
  );
};

export default ContentRight;
