import React from 'react';

const QuiSommesNous = () => {
  return (
    <main>
      <div>
        <h2>Qui Sommes Nous</h2>
      </div>
    </main>
  );
};

export default QuiSommesNous;
