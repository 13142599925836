import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Header from '../utilities/Header/Header';
import HeaderMenu from '../utilities/HeaderMenu/HeaderMenu';
import Footer from '../utilities/Footer/Footer';
import { useMyContext } from '../context/UseContext';
import { usePages } from './usePages';

const MyRoutes = () => {
  const { user, profil } = useMyContext();
  const { accessiblePages, loginPage, adminPage } = usePages(user, profil);

  return (
    <BrowserRouter basename='/'>
      <header>
        <Header />
        <HeaderMenu />
      </header>
      <Routes>
        {accessiblePages.map(({ component, path, connected, isloging }) => {
          const Component = component;
          return (
            <Route
              key={path}
              element={connected ? <Navigate to='/' replace /> : <Component />}
              path={path}
            />
          );
        })}

        {loginPage.map(({ component, path, isloging }) => {
          const Component = component;
          return (
            <Route
              key={path}
              element={
                isloging ? <Component /> : <Navigate to='/connexion' replace />
              }
              path={path}
            />
          );
        })}

        {adminPage.map(({ component, path, isAdmin }) => {
          const Component = component;
          return (
            <Route
              key={path}
              element={isAdmin ? <Component /> : <Navigate to='/' replace />}
              path={path}
            />
          );
        })}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
