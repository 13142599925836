import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyDfYYkk3FG5m8fnfunksABMHUr1JwRW1lk',
  authDomain: 'hemmy-885b8.firebaseapp.com',
  projectId: 'hemmy-885b8',
  storageBucket: 'hemmy-885b8.appspot.com',
  messagingSenderId: '128170980840',
  appId: '1:128170980840:web:210080ca26ecf95fbea05d',
};

// inti firebase

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
