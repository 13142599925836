import React, { useEffect, useRef } from 'react';
import './contacterNous.css';
import icon from '../../public/svg/contact.svg';
import { useFireStore } from '../../hooks/useFireStore';
import BarLoader from 'react-spinners/BarLoader';
import { toast } from 'react-toastify';
const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#4b02a4',
  width: '100%',
};
const ContacterNous = () => {
  const { addDocument, response } = useFireStore('messages');
  const { isPending, error, success } = response;
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const campanyRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const messageRef = useRef(null);

  const sendMessage = async (e) => {
    e.preventDefault();
    await addDocument({
      title: `Contact  ${nameRef.current.value}`,
      message: messageRef.current.value,
      isRead: false,
      email: emailRef.current.value,
      campany: campanyRef.current.value,
      name: nameRef.current.value,
      phoneNumber: phoneNumberRef.current.value,
    });
  };

  useEffect(() => {
    if (success) {
      toast.success('Message envoyé avec success', {
        position: 'top-center',
      });

      nameRef.current.value = '';
      messageRef.current.value = '';
      emailRef.current.value = '';
      campanyRef.current.value = '';
      phoneNumberRef.current.value = '';
    }

    if (error) {
      toast.error(`Erreur d'envoie`, {
        position: 'top-center',
      });
    }
  }, [success, error]);

  return (
    <main className='contact-container'>
      <div className='contact-left'>
        <img src={icon} alt='icone' />
      </div>
      <div>
        {isPending && (
          <BarLoader
            color='#4b02a4'
            loading={true}
            cssOverride={override}
            size={150}
            data-testid='loader'
          />
        )}

        <div className='contact-container__right'>
          <div className='contact-info'>
            <h3>Contactez-nous </h3>
            <p>
              Contactez-nous pour des questions, des collaborations ou des
              informations supplémentaires. Nous sommes là pour vous aider.
            </p>
          </div>

          <form onSubmit={sendMessage} className='contact-form'>
            <label>
              <span>Nom</span>
              <input ref={nameRef} type='text' />
            </label>
            <label>
              <span>Adresse Email</span>
              <input ref={emailRef} type='email' />
            </label>
            <label>
              <span>Nom de l'entreprise</span>
              <input ref={campanyRef} type='text' />
            </label>
            <label>
              <span>Numéro de téléphone</span>
              <input ref={phoneNumberRef} type='tel' />
            </label>
            <label>
              <span>Message</span>
              <textarea
                ref={messageRef}
                placeholder='Entrer votre message'
                rows={4}
              ></textarea>
            </label>
            <button type='submit'>Envoyer</button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default ContacterNous;
