import ProductDetails from '../srceens/ProductDetails';
import Auth from '../srceens/Auth';
import Favorite from '../srceens/Favorite';
// import Orders from '../srceens/Orders';
import MyOrders from '../srceens/admin/MyOrders';
import Account from '../srceens/admin/Account';
import AccountSetting from '../srceens/admin/AccountSetting';
import Notification from '../srceens/admin/Notification';
import AddProduct from '../srceens/admin/AddProduct';
import ProductList from '../srceens/admin/ProductList';
import NotFound from '../srceens/NotFound';
import ContacterNous from '../srceens/utilities/ContacterNous';
import Faqs from '../srceens/utilities/Faqs';
import QuiSommesNous from '../srceens/utilities/QuiSommesNous';
import Policy from '../srceens/utilities/Policy';
import TermsOfSale from '../srceens/utilities/TermsOfSale';
import CheckOut from '../srceens/CheckOut';
import Home from '../srceens/Home';
import Cart from '../srceens/Cart';
import EdictProduct from '../srceens/admin/EdictProduct';
import Orders from '../srceens/admin/Orders';
import Search from '../srceens/Search';
import Shop from '../srceens/Shop';

export function usePages(user, profil) {
  const accessiblePages = [
    { path: '/', exact: true, component: Home },
    { path: '/panier', exact: true, component: Cart },
    { path: '/produit/:id', exact: true, component: ProductDetails },
    { path: '/boutique', exact: true, component: Shop },
    { path: '/connexion', connected: user, exact: true, component: Auth },
    { path: '*', exact: true, component: NotFound },
    { path: '/contact', component: ContacterNous },
    { path: '/faqs', component: Faqs },
    { path: '/apropos-de-nous', component: QuiSommesNous },
    { path: '/plitique-de-confidentialite', component: Policy },
    { path: '/condition-generale-de-vente', component: TermsOfSale },
    { path: '/recherche', component: Search },
  ];

  const loginPage = [
    { path: '/payement/:id', isloging: user, component: CheckOut },
    {
      path: '/admin/parametre-compte',
      exact: true,
      isloging: user,
      component: AccountSetting,
    },
    { path: '/admin/account', isloging: user, exact: true, component: Account },
    {
      path: '/admin/my-orders',
      isloging: user,
      exact: true,
      component: MyOrders,
    },
    { path: '/favorite', isloging: user, exact: true, component: Favorite },
  ];

  const adminPage = [
    {
      path: '/admin/commandes',
      isAdmin: profil && profil.isAdmin,
      exact: true,
      component: Orders,
    },
    {
      path: '/admin/notifications',
      isAdmin: profil && profil.isAdmin,
      exact: true,
      component: Notification,
    },
    {
      path: '/admin/produits',
      isAdmin: profil && profil.isAdmin,
      exact: true,
      component: ProductList,
    },
    {
      path: '/admin/new/product',
      isAdmin: profil && profil.isAdmin,
      exact: true,
      component: AddProduct,
    },
    {
      path: '/admin/edict-product/:id',
      isAdmin: profil && profil.isAdmin,
      exact: true,
      component: EdictProduct,
    },
  ];

  return { accessiblePages, loginPage, adminPage };
}
