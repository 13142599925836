import { Fragment, useEffect, useState } from 'react';
import './productDetails.css';
import ThirdBtn from '../utilities/ThirdBtn';
import ProductCard from '../utilities/ProductCard';
import Comment from '../components/details/Comment';
import CommentList from '../components/details/CommentList';
import PopularCaroul from '../components/home/PopularCaroul';
import { useCollection } from '../hooks/useCollection';
import { useDocument } from '../hooks/useDocument';
import { useNavigate, useParams } from 'react-router-dom';
import { useMyContext } from '../context/UseContext';
import { liked, noLiked } from '../utils/likeHeart';
import Loader from '../utilities/Loader';
import { toast } from 'react-toastify';

const ProductDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { cartDispatch, cart } = useMyContext();
  const { message } = cart;
  const { documents, loading, error } = useCollection('products');
  const { docResponse } = useDocument('products', id);
  const { document, isPending, error: docError, success } = docResponse;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [numberProduct, setNumberProduct] = useState(2);
  const [qty, setQty] = useState(1);
  const getWidth = window.innerWidth;

  const [lastImageIndex, setLastImageIndex] = useState(3);
  const [indexOfFirstImage, setIndexOfFirstImage] = useState(0);

  const currentImages = document?.galleries?.slice(
    indexOfFirstImage,
    lastImageIndex
  );

  const next = () => {
    if (
      document?.galleries?.length === lastImageIndex ||
      document?.galleries?.length < 3
    ) {
      return;
    } else {
      setLastImageIndex((prevIndex) => prevIndex + 1);

      setIndexOfFirstImage((prevIndex) => prevIndex + 1);
    }
  };

  const prev = () => {
    if (indexOfFirstImage === 0) {
      return;
    } else {
      setLastImageIndex((prevIndex) => prevIndex - 1);
      setIndexOfFirstImage((prevIndex) => prevIndex - 1);
    }
  };

  const updateVisibleProducts = () => {
    if (getWidth >= 640) {
      setNumberProduct(2);
    } else {
      setNumberProduct(1);
    }
  };

  useEffect(() => {
    updateVisibleProducts();
    window.addEventListener('resize', updateVisibleProducts);
    return () => {
      window.removeEventListener('resize', updateVisibleProducts);
    };
  }, [getWidth, numberProduct]);

  const AddToCartHadler = () => {
    const product = {
      name: document.name,
      id: document.id,
      image: document.galleries[0],
      price: document.price,
      qty: qty,
    };

    cartDispatch({ type: 'ADD_TO_CART', payload: product });
  };

  function byHandler() {
    const product = {
      name: document.name,
      id: document.id,
      image: document.galleries[0],
      price: document.price,
      qty: qty,
    };

    cartDispatch({ type: 'ADD_TO_CART', payload: product });
    navigate('/panier');
  }

  const currentDoc = documents.filter((document) => document.id !== id);

  useEffect(() => {
    if (message) {
      toast.success(message, {
        position: 'top-center',
      });
    }
  }, [message]);

  return (
    <main className='product-details'>
      {isPending || loading ? (
        <div
          style={{
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '30vh',
          }}
        >
          <Loader />
        </div>
      ) : docError || error ? (
        <span className='error'>{docError || error}</span>
      ) : (
        <>
          <div className='product-container'>
            <h3 className='product-container__title'>{document?.name}</h3>
            <div className='product-container__header'>
              <ul className='product-container__header-imgs'>
                <button
                  disabled={indexOfFirstImage === 0}
                  onClick={prev}
                  className={`product-container__header-imgs-button `}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    className='product-container__header-imgs-svg__icon'
                  >
                    <path
                      fillRule='evenodd'
                      d='M9.47 6.47a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.06l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z'
                      clipRule='evenodd'
                    />
                  </svg>
                </button>
                {currentImages.map((image, index) => (
                  <li
                    className={`product-container__header-imgs__card ${
                      index === currentIndex - indexOfFirstImage && 'active'
                    } `}
                    onClick={() => {
                      setCurrentIndex(index + indexOfFirstImage);
                    }}
                    key={index}
                  >
                    <img
                      className='product-container__header-img'
                      src={image}
                      alt='image'
                    />
                  </li>
                ))}
                <button
                  disabled={
                    document?.galleries?.length === lastImageIndex ||
                    document?.galleries?.length <= 3
                  }
                  onClick={next}
                  className={`product-container__header-imgs-button  `}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    className='product-container__header-imgs-svg__icon'
                  >
                    <path
                      fillRule='evenodd'
                      d='M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z'
                      clipRule='evenodd'
                    />
                  </svg>
                </button>
              </ul>
              <div className='product-container__header__image'>
                <img
                  className='product-container__header__img'
                  src={document?.galleries[currentIndex]}
                  alt='image'
                />
                <ul
                  style={{ alignItems: 'center' }}
                  className='product-grid__imgs'
                >
                  <button
                    disabled={indexOfFirstImage === 0}
                    onClick={prev}
                    className={`product-container__header-imgs-mobile-button `}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                      style={{ height: '2rem', width: '2rem' }}
                    >
                      <path
                        fillRule='evenodd'
                        d='M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z'
                        clipRule='evenodd'
                      />
                    </svg>
                  </button>
                  {currentImages.map((image, index) => (
                    <li
                      className={`product-grid__imgs__card ${
                        index === currentIndex - indexOfFirstImage && 'active'
                      } `}
                      onClick={() => setCurrentIndex(index + indexOfFirstImage)}
                      key={index}
                    >
                      <img
                        className='product-grid__imgs-img'
                        src={image}
                        alt='image'
                      />
                    </li>
                  ))}
                  <button
                    disabled={document?.galleries?.length === lastImageIndex}
                    onClick={next}
                    className={`product-container__header-imgs-mobile-button  `}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                      style={{ height: '2rem', width: '2rem' }}
                    >
                      <path
                        fillRule='evenodd'
                        d='M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z'
                        clipRule='evenodd'
                      />
                    </svg>
                  </button>
                </ul>
              </div>
              <div className='product-container__header-desc'>
                <div>
                  <span>Quantité</span>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '10px',
                    }}
                  >
                    <button
                      onClick={() => {
                        if (qty !== 1) {
                          setQty((prev) => prev - 1);
                        }
                      }}
                      style={{
                        backgroundColor: '#ac52d3',
                        padding: '5px 15px',
                        color: '#fff',
                        border: 'none',
                        fontSize: '25px',
                        cursor: 'pointer',
                      }}
                    >
                      -
                    </button>
                    <span
                      style={{
                        fontSize: '20px',
                        fontWeight: '500',
                        backgroundColor: '#d5c3dd',
                        padding: '7.5px 20px',
                        color: '#fff',
                      }}
                    >
                      {qty}
                    </span>
                    <button
                      onClick={() => {
                        setQty((prev) => prev + 1);
                      }}
                      style={{
                        backgroundColor: '#ac52d3',
                        padding: '5px 15px',
                        color: '#fff',
                        border: 'none',
                        fontSize: '25px',
                        cursor: 'pointer',
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
                <span className='product-container__header-price'>
                  €{document?.price}
                </span>
                <div className='product-container__header-btn'>
                  <div
                    style={{ marginBottom: '10px', boxSizing: 'border-box' }}
                  >
                    <ThirdBtn onClick={AddToCartHadler}>
                      Ajouter Au Panier
                    </ThirdBtn>
                  </div>
                  <div>
                    <ThirdBtn onClick={byHandler}>Acheter maintainant</ThirdBtn>
                  </div>
                </div>
                <div>
                  <h5 className='product-container__second-title'>
                    Descriprion
                  </h5>
                  <p style={{ margin: '0px', opacity: 0.7 }}>
                    {document?.description}
                  </p>
                </div>
              </div>
            </div>
            <div className='product-container__midle'>
              <div>
                <div>
                  <h5 className='product-container__second-title'>
                    Composition
                  </h5>
                  <p style={{ margin: '0px', opacity: 0.7 }}>
                    {document?.composition}
                  </p>
                </div>
                {document && document.spcifications.length > 0 && (
                  <div>
                    <h5 className='product-container__second-title'>
                      Spécifications
                    </h5>
                    <ul className='product-container__header-spefications'>
                      {document?.spcifications?.map((spe, index) => (
                        <li
                          className='product-container__header-spefication'
                          key={index}
                        >
                          <span>{spe?.value}</span>
                          <span>{spe?.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div>
                {currentDoc.filter((doc) => doc.category === document.category)
                  .length > 0 && (
                  <>
                    {' '}
                    <h5 className='product-container__second-title'>
                      Avec les recommandations de produits
                    </h5>
                    <div className='product-container__midle-recomand'>
                      {loading ? (
                        <div
                          style={{
                            width: '100vw',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Loader />
                        </div>
                      ) : error ? (
                        <span className='error'>{error}</span>
                      ) : (
                        currentDoc
                          .filter((doc) => doc.category === document.category)
                          .slice(0, numberProduct)
                          .map((product, index) => (
                            <ProductCard
                              liked={liked}
                              noLiked={noLiked}
                              product={product}
                              key={index}
                              index={index}
                            />
                          ))
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div>
              <h5 className='product-container__second-title'>Feedback</h5>
              <Comment id={id} document={document} />
            </div>
            <div>
              {document && document.comments.length > 0 && (
                <CommentList document={document.comments} />
              )}
            </div>
          </div>{' '}
          <div>
            <PopularCaroul
              documents={currentDoc}
              loading={loading}
              error={error}
              title={'Produits similaire'}
            />
          </div>
        </>
      )}
    </main>
  );
};

export default ProductDetails;
