import React from 'react';

const NotproductFound = () => {
  return (
    <>
      <div
        style={{
          width: ' 100%',
          backgroundColor: '#f6c6c6f5',
          color: '#e22b2b',
          display: 'inline-block',
          padding: ' 10px',
        }}
        className='Error-container'
      >
        <p>Aucun produit trouvé.</p>
      </div>
    </>
  );
};

export default NotproductFound;
