import React, { useEffect, useState } from 'react';
import RatingStars from '../../utilities/RatingStars';
import SecondaryBtn from '../../utilities/SecondaryBtn';
import RateStars from '../../utilities/RateStars';
import { useFireStore } from '../../hooks/useFireStore';
import { useMyContext } from '../../context/UseContext';
import BarLoader from 'react-spinners/BarLoader';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#4b02a4',
  width: '100%',
};

const Comment = ({ id, document }) => {
  const { user, profil } = useMyContext();
  const { updateDocument, response } = useFireStore('products');
  const { document: DOC, isPending, error, success } = response;
  const navigate = useNavigate();

  const [rating, setRating] = useState(0);
  const [text, setText] = useState('');

  const submitHandler = (e) => {
    e.preventDefault();
    if (user) {
      const newComment = {
        rate: rating,
        text: text,
        uid: user.uid,
        profil: profil.photo,
        name: profil.name,
        createdAt: Date.now(),
      };
      document.comments.push(newComment);

      // let totalRating = 0;
      // document.comments.forEach((comment) => {
      //   totalRating += comment.rate;
      // });

      const totalRating = document.comments.reduce(
        (acc, cur) => acc + cur.rate,
        0
      );
      const newAverageRating = (totalRating / document.comments.length).toFixed(
        1
      );
      // const newAverageRating = totalRating / document.comments.length;

      updateDocument(id, {
        rating: newAverageRating,
        comments: document.comments,
      });
      toast.success('Commentaire ajouté avec succès', {
        position: 'top-center',
      });
    } else {
      navigate('/connexion');
    }
  };

  useEffect(() => {
    setText('');
    setRating(0);
  }, [success]);
  return (
    <div className='comment'>
      <form onSubmit={submitHandler} className='comment-form'>
        <BarLoader
          color='#4b02a4'
          loading={isPending}
          cssOverride={override}
          size={150}
          data-testid='loader'
        />
        {error && <span className='error'>{error}</span>}
        <RateStars rating={rating} setRating={setRating} />
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          type='text'
          rows={4}
          className='comment-form__textarea'
        />
        <button className='comment-form__btn'>Ajouter un commentaire</button>
      </form>
      <div className='comment-rate'>
        <h4 className='comment-rate__number'>{document?.rating}</h4>
        <span className='comment-rate__count'>
          {document?.comments?.length} commentaires
        </span>
        <RatingStars rating={(document && document.rating) ?? 0} />
      </div>
    </div>
  );
};

export default Comment;
