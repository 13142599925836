import React, { useState } from 'react';
import ProductCard from '../utilities/ProductCard';
import { products } from '../data/data';
import './favorite.css';
import { useCollection } from '../hooks/useCollection';
import { liked, noLiked } from '../utils/likeHeart';
import Pagination from '../utilities/Pagination';
import Loader from '../utilities/Loader';
import { Link } from 'react-router-dom';

const Favorite = () => {
  const { documents, loading } = useCollection('favorites');
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(6);

  const indexOfLastProduct = currentPage * productsPerPage;

  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const currentProducts = documents.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <main className='favorite'>
      <div className='favorite__cards'>
        {loading ? (
          <Loader />
        ) : documents.length === 0 ? (
          <div className='empty-favori__ontainer'>
            <div className='empty-favori__empty'>
              <h2>Votre panier est vide</h2>
              <p>
                Commencez vos achats pour ajouter des produits à votre panier.
              </p>
              <Link to={'/boutique'}>
                <a className='btn-back-to-shop'>Retour à la boutique</a>
              </Link>
            </div>
          </div>
        ) : (
          currentProducts.map((product, index) => (
            <ProductCard
              liked={liked}
              noLiked={liked}
              product={product}
              index={index}
              key={index}
            />
          ))
        )}
      </div>
      <Pagination
        documents={documents}
        productsPerPage={productsPerPage}
        currentPage={currentPage}
        paginate={paginate}
      />
    </main>
  );
};

export default Favorite;
