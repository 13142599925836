import React, { useEffect, useState } from 'react';
import Layout from '../../components/admin/Layout';
import InputFied from '../../utilities/InputFied';
import './addproduct.css';
import TeaxtArea from '../../utilities/TeaxtArea';
import FourthButton from '../../utilities/FourthButton';
import SelectInput from '../../utilities/SelectInput';
import {
  categories,
  sousCategories,
  marques,
  delevery,
  MyCategories,
} from './../../data/data';
import { useStorage } from '../../hooks/useStorage';
import { useMyContext } from '../../context/UseContext';
import { useFireStore } from '../../hooks/useFireStore';
import { useNavigate, useParams } from 'react-router-dom';
import BarLoader from 'react-spinners/BarLoader';
import { useDocument } from '../../hooks/useDocument';

const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#4b02a4',
  width: '100%',
};

const types = ['Normal', 'en Vedette'];

const EdictProduct = () => {
  const { user } = useMyContext();
  const { id } = useParams();
  const { docResponse } = useDocument('products', id);
  const { updateDocument, response } = useFireStore('products');
  const { document, isPending, error, success } = response;
  const navigate = useNavigate();

  const {
    images,
    progress,
    uploadFiles,
    uploadErr,
    deleteFile,
    setUploadErr,
    successfully,
    isdeletd,
    success: imagaSucces,
  } = useStorage();

  const [name, setName] = useState();
  const [price, setPrice] = useState();
  const [composition, setComposition] = useState();
  const [category, setCategory] = useState();
  const [description, setDescription] = useState();
  const [spcifications, setSpcifications] = useState([]);
  const [speVal, setSpeVal] = useState('');
  const [speName, setSpeName] = useState('');
  const [galleries, setGalleries] = useState([]);
  const [galleriesToDelete, setGalleriesToDelete] = useState([]);
  const [sellingTypes, setSellingTypes] = useState('Normal');

  const handleUpload = (event) => {
    if (event.target.files.length > 0) {
      uploadFiles(event.target.files, user);
    }
  };

  useEffect(() => {
    if (imagaSucces) {
      const upload = [];
      upload.push(...galleries, ...images);
      setGalleries(upload);
    }
  }, [imagaSucces]);

  const addSpecification = () => {
    setSpcifications((newSpec) => [
      ...newSpec,
      { value: speVal, name: speName },
    ]);
    setSpeName('');
    setSpeVal('');
  };

  const removeSpeHandler = (id) => {
    const newSpecification = spcifications.filter(
      (spec, index) => index !== id
    );
    setSpcifications(newSpecification);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    // for (let image of galleriesToDelete) {
    //   await deleteFile(image);
    // }

    await Promise.all(galleriesToDelete.map((image) => deleteFile(image)));
    await updateDocument(id, {
      ...docResponse.document,
      name: name.toLowerCase(),
      price,
      composition,
      category,
      description,
      spcifications,
      updatedAt: Date.now(),
      galleries: galleries,
      sellingTypes,
    });
  };

  useEffect(() => {
    if (success) {
      navigate('/admin/produits');
    }
  }, [success]);

  useEffect(() => {
    if (docResponse.success) {
      setName(docResponse.document.name);
      setPrice(docResponse.document.price);
      setComposition(docResponse.document.composition);
      setCategory(docResponse.document.category);
      setDescription(docResponse.document.description);
      setSpcifications(docResponse.document.spcifications);
      setGalleries(docResponse.document.galleries);
      setSellingTypes(docResponse.document.sellingTypes);
    }
  }, [docResponse.success]);

  const deleteFileHadler = async (imageUrl, index) => {
    setGalleriesToDelete((prev) => [...prev, imageUrl]);
    setGalleries((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <Layout>
      <div className='new-product'>
        <BarLoader
          color='#4b02a4'
          loading={isPending}
          cssOverride={override}
          size={150}
          data-testid='loader'
        />
        {error && <span className='error'>{error}</span>}
        <form onSubmit={submitHandler} className='new-product__form'>
          <InputFied
            onChange={(e) => setName(e.target.value)}
            value={name}
            text='Entrer votre nom'
            type='text'
          />
          <InputFied
            onChange={(e) => {
              const inputValue = e.target.value;
              const numericValue = inputValue.replace(/\D/g, '');
              setPrice(numericValue);
            }}
            value={price}
            text='Entrer le prix'
            type='text'
          />
          <SelectInput
            value={category}
            options={MyCategories}
            text='Selectionner une categories'
            onChange={(e) => setCategory(e.target.value)}
          />

          <SelectInput
            value={sellingTypes}
            options={types}
            text='Selectionner le type'
            onChange={(e) => setSellingTypes(e.target.value)}
          />

          <TeaxtArea
            onChange={(e) => setComposition(e.target.value)}
            value={composition}
            text='Donner des composition'
            type='text'
            rows={4}
          />
          <TeaxtArea
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            text='Entrer la description'
            type='text'
            rows={5}
          />
          <div className='new-product__form-specifi'>
            <InputFied
              onChange={(e) => setSpeVal(e.target.value)}
              value={speVal}
              text='Entrer une spécification'
              type='text'
            />
            <InputFied
              onChange={(e) => setSpeName(e.target.value)}
              value={speName}
              text='Entrer la valeur'
              type='text'
            />
            <span onClick={addSpecification}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                style={{
                  height: '30px',
                  width: '30px',
                  cursor: 'pointer',
                  color: '#a86ad1',
                }}
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M12 4.5v15m7.5-7.5h-15'
                />
              </svg>
            </span>
          </div>
          {spcifications.length > 0 && (
            <ul className='new-product__form__items'>
              {spcifications.map((sp, index) => (
                <li className='new-product__form__item'>
                  <span>{sp.value}</span>
                  <span>{sp.name}</span>
                  <span
                    onClick={() => removeSpeHandler(index)}
                    className='new-product__form__item-remove'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke-width='1.5'
                      stroke='currentColor'
                      style={{ height: '20px', width: '20px', color: 'red' }}
                    >
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        d='M6 18 18 6M6 6l12 12'
                      />
                    </svg>
                  </span>
                </li>
              ))}
            </ul>
          )}

          <label className='new-product__form-file__input' htmlFor='files'>
            <input
              style={{ display: 'none' }}
              multiple
              type='file'
              id='files'
              onChange={handleUpload}
            />
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='w-6 h-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z'
              />
            </svg>
          </label>
          {progress > 0 && (
            <div style={{ backgroundColor: '#690c9b3c', width: '100%' }}>
              <span
                style={{
                  display: 'flex',
                  width: `${progress}%`,
                  backgroundColor: '#690c9b',
                  margin: '5px 0px',
                  color: 'white',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {progress}%
              </span>
            </div>
          )}
          {galleries.length > 0 && (
            <div className='new-product__form-imgs'>
              {galleries.map((imageUrl, index) => (
                <div key={index} className='new-product__form-img'>
                  <img src={imageUrl} alt={`Image ${index}`} />
                  <svg
                    onClick={() => deleteFileHadler(imageUrl, index)}
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='w-6 h-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M6 18 18 6M6 6l12 12'
                    />
                  </svg>
                </div>
              ))}{' '}
            </div>
          )}

          <FourthButton>Mise à jour</FourthButton>
        </form>
      </div>
    </Layout>
  );
};

export default EdictProduct;
