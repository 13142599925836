import React, { useEffect, useState } from 'react';
import './order.css';
import { useCollection } from '../../hooks/useCollection';
import Layout from '../../components/admin/Layout';
import Pagination from '../../utilities/Pagination';
import { useFireStore } from '../../hooks/useFireStore';
import { toast } from 'react-toastify';

const Orders = () => {
  const { documents, loading, error } = useCollection('orders');
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(6);
  const { updateDocument, response } = useFireStore('orders');

  const indexOfLastProduct = currentPage * productsPerPage;

  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const currentProducts = documents.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const deliveryHandler = async (order) => {
    if (window.confirm('Voulez vous marqué comme livré?')) {
      await updateDocument(order.id, {
        ...order,
        cartItems: [...order.cartItems],
        isShipped: true,
      });
    }
  };

  useEffect(() => {
    if (response.success) {
      toast.success('Livraison marquée avec succès!', {
        position: 'top-center',
      });
    }
  }, [response.success]);

  return (
    <Layout>
      <div>
        <div className='order'>
          <table>
            <tr>
              <th scope='col'>ID</th>
              <th scope='col'>Produit</th>
              <th scope='col'>Quantité</th>
              <th scope='col'>Montant</th>
              <th scope='col'>Clients</th>
              <th scope='col'>Action</th>
            </tr>
            {currentProducts.map((order) => (
              <tr key={order.id}>
                <th scope='row'>{order.id.substring(10, order.id.length)}</th>
                <td>
                  {order.cartItems.map((items) => (
                    <ul
                      style={{
                        listStyle: 'none',
                        padding: 0,
                      }}
                    >
                      <li
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <span>
                            <img
                              style={{
                                height: '60px',
                                width: '60px',
                                objectFit: 'cover',
                              }}
                              src={items.image}
                              alt='image'
                            />
                          </span>
                          <span>{items.name}</span>
                        </div>

                        <span>{items.price}€</span>
                      </li>
                    </ul>
                  ))}
                </td>
                <td>
                  {order.cartItems.map((items) => (
                    <ul
                      style={{
                        listStyle: 'none',
                        padding: 0,
                      }}
                    >
                      <li
                        style={{
                          height: '60px',
                          width: '60px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <span>{items.qty}</span>
                      </li>
                    </ul>
                  ))}
                </td>
                <td>{order.totalPrice}€</td>
                <td>
                  <ul style={{ listStyle: 'outside' }}>
                    <li>
                      <span>Nom:</span>
                      <span>{order.customName}</span>
                    </li>
                    <li>
                      <span>Email:</span>
                      <span>{order.email_address}</span>
                    </li>
                    <li>
                      <span>Tél:</span>
                      <span>{order.phoneNumber}</span>
                    </li>
                    <li>
                      <span>Adreesse:</span>
                      <span>{order.shippingAddresse}</span>
                    </li>
                  </ul>
                </td>
                <td>
                  {order.isShipped ? (
                    <span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        fill='currentColor'
                        style={{
                          height: '30px',
                          width: '30px',
                          cursor: 'pointer',
                          color: '#2eb71f',
                        }}
                      >
                        <path
                          fillRule='evenodd'
                          d='M9 1.5H5.625c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5Zm6.61 10.936a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 14.47a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z'
                          clipRule='evenodd'
                        />
                        <path d='M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z' />
                      </svg>
                    </span>
                  ) : (
                    <span
                      onClick={() => deliveryHandler(order)}
                      title='marquer comme livré'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        fill='currentColor'
                        style={{
                          height: '30px',
                          width: '30px',
                          cursor: 'pointer',
                          color: '#7100bc',
                        }}
                      >
                        <path
                          fillRule='evenodd'
                          d='M9 1.5H5.625c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5Zm6.61 10.936a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 14.47a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z'
                          clipRule='evenodd'
                        />
                        <path d='M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z' />
                      </svg>
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </table>
        </div>
        <Pagination
          documents={documents}
          productsPerPage={productsPerPage}
          currentPage={currentPage}
          paginate={paginate}
        />
      </div>
    </Layout>
  );
};

export default Orders;
