import { useEffect, useRef, useState } from 'react';
import { db } from '../firebase/config';
import {
  collection,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';

export function useUserCollection(collectionDoc, uid) {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    let ref = collection(db, collectionDoc);

    const q = query(ref, where('uid', '==', uid));
    const unsub = onSnapshot(
      q,
      (snapshop) => {
        let results = [];

        snapshop.docs.forEach((doc) => {
          results.push({ id: doc.id, ...doc.data() });
        });

        setLoading(false);
        setDocuments(results);
      },
      (err) => {
        setLoading(false);
        setError('Impossible de recurer les données');
      }
    );

    return () => unsub();
  }, [collectionDoc]);

  return { documents, loading, error };
}

// import { collection, query, orderBy, limit, startAfter, endBefore } from 'firebase/firestore';
// import { db } from '../firebase/config';

// const fetchProducts = async (lastProduct) => {
//   const productsRef = collection(db, 'products');

//   // Query de base trié par nom
//   let q = query(productsRef, orderBy('name'));

//   // Si lastProduct est défini, commence après ce produit
//   if (lastProduct) {
//     q = query(q, startAfter(lastProduct));
//   }

//   q = query(q, limit(10));

//   const snapshot = await getDocs(q);
//   const products = snapshot.docs.map((doc) => ({
//     id: doc.id,
//     ...doc.data(),
//   }));

//   return products;
// };

// export default fetchProducts;
